import * as models from '../../store/models/store.models';
import * as alternativeMedicament from '../../store/actions/alternativeMedicaments.action';

const estadoInicial: models.alternativeMedicamentState = {
  AlternativeMedicamentRequest: null,
};

export function alternativeMedicamentReducer(
  state = estadoInicial,
  action: alternativeMedicament.alternativeMedicamentActions
): models.alternativeMedicamentState {
  switch (action.type) {
    case alternativeMedicament.SETALTERNATIVEMEDICAMENTREQUEST:
      return {
        ...state,
        AlternativeMedicamentRequest: action.AlternativeMedicamentRequest,
      };

    case alternativeMedicament.UNSETALTERNATIVEMEDICAMENTREQUEST:
      return {
        ...state,
        AlternativeMedicamentRequest: null,
      };
    default:
      return state;
  }
}
