import { SugerenciaModel } from '../../models/sugerencia.model';
import { Action } from '@ngrx/store';

export const SETSUGERENCIASREQUEST =
    '[sugerencias] Set SUGERENCIASREQUEST';
export const UNSETSUGERENCIASREQUEST =
    '[sugerencias] UnSet SUGERENCIASREQUEST';

export class setSugerenciasRequest implements Action {
    readonly type = SETSUGERENCIASREQUEST;
    constructor(public SugerenciasRequest: SugerenciaModel | null) { }
}

export class unsetSugerenciasRequest implements Action {
    readonly type = UNSETSUGERENCIASREQUEST;
}

export type sugerenciasActions =
    | setSugerenciasRequest
    | unsetSugerenciasRequest
