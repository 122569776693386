import { Item } from '../../models/datosTransaccion.models';
import { Action } from '@ngrx/store';

export const SETALTERNATIVEMEDICAMENTREQUEST =
    '[alternativeMedicament] Set SETALTERNATIVEMEDICAMENTREQUEST';
export const UNSETALTERNATIVEMEDICAMENTREQUEST =
    '[alternativeMedicament] UnSet SETALTERNATIVEMEDICAMENTREQUEST';

export class setAlternativeMedicamentRequest implements Action {
    readonly type = SETALTERNATIVEMEDICAMENTREQUEST;
    constructor(public AlternativeMedicamentRequest: Item[] | null) { }
}

export class unsetAlternativeMedicamentRequest implements Action {
    readonly type = UNSETALTERNATIVEMEDICAMENTREQUEST;
}

export type alternativeMedicamentActions =
    | setAlternativeMedicamentRequest
    | unsetAlternativeMedicamentRequest
