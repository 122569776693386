import { Receta } from '../../models/rescateRecetas.model';
import { Action } from '@ngrx/store';

export const SETRECETASREQUEST =
    '[recetas] Set SETRECETASREQUEST';
export const UNSETRECETASREQUEST =
    '[recetas] UnSet SETRECETASREQUEST';

export class setRecetasRequest implements Action {
    readonly type = SETRECETASREQUEST;
    constructor(public RecetasRequest: Receta | null) { }
}

export class unsetRecetasRequest implements Action {
    readonly type = UNSETRECETASREQUEST;
}

export type recetasActions =
    | setRecetasRequest
    | unsetRecetasRequest
