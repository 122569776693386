import { inject } from '@angular/core';
import { HttpRequest, HttpEvent, HttpErrorResponse, HttpHandlerFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { removeDatosUsuario } from 'app/store/actions';
import { AppState } from 'app/store/app.reducers';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

/**
 
Intercept*
@param req
@param next
*/
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const store = inject(Store<AppState>)
  const router = inject(Router)
  // Clone the request object
  let newReq = req.clone();

  return next(newReq).pipe(
    catchError((error) => {
      // Catch "401 Unauthorized" responses
      if (error instanceof HttpErrorResponse && (error.status === 401)) {
        // Sign out
          store.dispatch(new removeDatosUsuario());
          router.navigate(['/sign-in']);
      }
      return throwError(error);
    }),
  );
};