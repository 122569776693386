import { Financiador } from '../../models/financiador.model';
import { codigoInstalacion } from '../../models/global.models';
import { UsuarioActualModel } from '../../models/login.models';
import { lugarAtencion } from '../../models/lugarAtencion.model';
import { Prestador, LugarAtencion, PrestadorSelecionado } from '../../models/prestador.model';
import { Transacciones } from '../../models/transaccion.models';
import * as datosTransaccion from '../../models/datosTransaccion.models';
import * as datosInicio from '../../models/datosInicio.models';
import { codigoNombreModel, DatosInicialesExtra } from '../../models/datosInicialesExtras.models';
import * as datosDispensa from '../../models/datosDispensa.models';
import { TicketFiltrosModel } from '../../models/ticket.models';
import { SugerenciaModel } from '../../models/sugerencia.model';
import { Receta } from '../../models/rescateRecetas.model';


//Models para dispensar prescripciones
export class datosDispensaState {
  stepMonodrogaRequest: datosDispensa.NumeroStepModel | null;
  monodrogaRequest: datosDispensa.MonodrogaRequestModel | null;
  medicamentoSeleccionadoRequest: datosTransaccion.DetalleMedicamentosRequestModel | null;

  constructor(
    stepMonodrogaRequest: datosDispensa.NumeroStepModel,
    monodrogaRequest: datosDispensa.MonodrogaRequestModel,
    medicamentoSeleccionadoRequest: datosTransaccion.DetalleMedicamentosRequestModel
  ) {
    this.stepMonodrogaRequest = stepMonodrogaRequest;
    this.monodrogaRequest = monodrogaRequest;
    this.medicamentoSeleccionadoRequest = medicamentoSeleccionadoRequest;
  }
}
//Models para estado forms
export class estadoFormsState {
  formEstadoCredencialRequest: boolean | null;
  formEstadoCodigoDeSeguridadRequest: boolean | null;
  formEstadoFinanciadorRequest: boolean | null;
  formEstadoPrestadorRequest: boolean | null;
  formEstadoTransaccionRequest: boolean | null;
  constructor(
    formEstadoCredencialRequest: boolean,
    formEstadoCodigoDeSeguridadRequest: boolean,
    formEstadoFinanciadorRequest: boolean,
    formEstadoPrestadorRequest: boolean,
    formEstadoTransaccionRequest: boolean,
    
  ) {
    this.formEstadoCredencialRequest = formEstadoCredencialRequest;
    this.formEstadoCodigoDeSeguridadRequest = formEstadoCodigoDeSeguridadRequest;
    this.formEstadoFinanciadorRequest = formEstadoFinanciadorRequest;
    this.formEstadoPrestadorRequest = formEstadoPrestadorRequest;
    this.formEstadoTransaccionRequest = formEstadoTransaccionRequest;
  }
}
//Models para transaccion informar prestacion
export class datosTransaccionesState {
  DetalleMedicamentosRequest: Array<datosTransaccion.DetalleMedicamentosRequestModel> | null;
  extraDispensaRequest: datosTransaccion.ExtrasDispensa | null | undefined;
  DetalleProcedimientoRequest: Array<datosTransaccion.DetalleProcedimientoRequestModel> | null;
  AcompannanteRequest: datosTransaccion.AcompannanteRequestModel | null;
  AtencionRequest: datosTransaccion.AtencionRequestModel | null;
  CodFinalizacionTratamientoRequest: string | null;
  DerivacionRequest: datosTransaccion.DerivacionRequestModel | null;
  DiagnosticosRequest: Array<datosTransaccion.DiagnosticosRequestModel> | null;
  DocumentacionRequest: datosTransaccion.DocumentacionRequestModel | null;
  EfectorRequest: datosTransaccion.EfectorRequestModel | null;
  MensajeParaFinanciadorRequest: string | null;
  PreautorizacionRequest: datosTransaccion.PreautorizacionRequestModel | null;
  PrescripcionRequest: datosTransaccion.PrescripcionRequestModel | null;
  PrescriptorRequest: datosTransaccion.PrescriptorRequestModel | null;
  FechaReferenciaCancelRequest: string | null;
  IdMsjRequest: string | null;
  InicioTrxRequest: datosTransaccion.InicioTrxRequestModel | null;
  NroReferenciaCancelRequest: string | null;
  NroReferenciaRequest: string | null;
  SoftwareRequest: datosTransaccion.SoftwareRequestModel | null;
  TerminalRequest: datosTransaccion.TerminalRequestModel | null;
  TipoTransaccionRequest: string | null;
  VersionMsjRequest: string | null;

  constructor(
    DetalleMedicamentosRequest: Array<datosTransaccion.DetalleMedicamentosRequestModel>,
    extraDispensaRequest: datosTransaccion.ExtrasDispensa,
    DetalleProcedimientoRequest: Array<datosTransaccion.DetalleProcedimientoRequestModel>,
    AcompannanteRequest: datosTransaccion.AcompannanteRequestModel,
    AtencionRequest: datosTransaccion.AtencionRequestModel,
    CodFinalizacionTratamientoRequest: string,
    DerivacionRequest: datosTransaccion.DerivacionRequestModel,
    DiagnosticosRequest: Array<datosTransaccion.DiagnosticosRequestModel>,
    DocumentacionRequest: datosTransaccion.DocumentacionRequestModel,
    EfectorRequest: datosTransaccion.EfectorRequestModel,
    MensajeParaFinanciadorRequest: string,
    PreautorizacionRequest: datosTransaccion.PreautorizacionRequestModel,
    PrescripcionRequest: datosTransaccion.PrescripcionRequestModel,
    PrescriptorRequest: datosTransaccion.PrescriptorRequestModel,
    FechaReferenciaCancelRequest: string,
    IdMsjRequest: string,
    InicioTrxRequest: datosTransaccion.InicioTrxRequestModel,
    NroReferenciaCancelRequest: string,
    NroReferenciaRequest: string,
    SoftwareRequest: datosTransaccion.SoftwareRequestModel,
    TerminalRequest: datosTransaccion.TerminalRequestModel,
    TipoTransaccionRequest: string,
    VersionMsjRequest: string
  ) {
    this.DetalleMedicamentosRequest = DetalleMedicamentosRequest;
    this.extraDispensaRequest = extraDispensaRequest;
    this.DetalleProcedimientoRequest = DetalleProcedimientoRequest;
    this.AcompannanteRequest = AcompannanteRequest;
    this.AtencionRequest = AtencionRequest;
    this.CodFinalizacionTratamientoRequest = CodFinalizacionTratamientoRequest;
    this.DerivacionRequest = DerivacionRequest;
    this.DiagnosticosRequest = DiagnosticosRequest;
    this.DocumentacionRequest = DocumentacionRequest;
    this.EfectorRequest = EfectorRequest;
    this.MensajeParaFinanciadorRequest = MensajeParaFinanciadorRequest;
    this.PreautorizacionRequest = PreautorizacionRequest;
    this.PrescripcionRequest = PrescripcionRequest;
    this.PrescriptorRequest = PrescriptorRequest;
    this.FechaReferenciaCancelRequest = FechaReferenciaCancelRequest;
    this.IdMsjRequest = IdMsjRequest;
    this.InicioTrxRequest = InicioTrxRequest;
    this.NroReferenciaCancelRequest = NroReferenciaCancelRequest;
    this.NroReferenciaRequest = NroReferenciaRequest;
    this.SoftwareRequest = SoftwareRequest;
    this.TerminalRequest = TerminalRequest;
    this.TipoTransaccionRequest = TipoTransaccionRequest;
    this.VersionMsjRequest = VersionMsjRequest;
  }
}

//Models para datosInicio
export class datosInicioState {
  public CredencialRequest: datosInicio.CredencialRequestModel | null;
  public FinanciadorRequest: datosInicio.FinanciadorRequestModel | null;
  public PrestadorRequest: datosInicio.PrestadorRequestModel | null;
  public TransaccionRequest: datosInicio.TransaccionRequestModel | null;
  public PrescripcionDispensaRequest: datosInicio.PrescripcionDispensaRequestModel | null;
  public AfiliadoRequest: datosInicio.AfiliadoRequestModel | null;
  public BeneficiarioRequest: datosInicio.BeneficiarioRequestModel | null;

  constructor(
    CredencialRequest: datosInicio.CredencialRequestModel,
    FinanciadorRequest: datosInicio.FinanciadorRequestModel,
    PrestadorRequest: datosInicio.PrestadorRequestModel,
    TransaccionRequest: datosInicio.TransaccionRequestModel,
    PrescripcionDispensaRequest: datosInicio.PrescripcionDispensaRequestModel,
    AfiliadoRequest: datosInicio.AfiliadoRequestModel,
    BeneficiarioRequest: datosInicio.BeneficiarioRequestModel
  ) {
    this.CredencialRequest = CredencialRequest;
    this.FinanciadorRequest = FinanciadorRequest;
    this.PrestadorRequest = PrestadorRequest;
    this.TransaccionRequest = TransaccionRequest;
    this.PrescripcionDispensaRequest = PrescripcionDispensaRequest;
    this.AfiliadoRequest = AfiliadoRequest;
    this.BeneficiarioRequest = BeneficiarioRequest;
  }
}

export class financiadorForm {
  financiador: Financiador;
  formValid: boolean;

  constructor(financiador: Financiador, formValid: boolean) {
    this.financiador = financiador;
    this.formValid = formValid;
  }
}

export class numeroAfiliadoForm {
  numeroAfiliado: string;
  formValid: boolean;

  constructor(numeroAfiliado: string, formValid: boolean) {
    this.numeroAfiliado = numeroAfiliado;
    this.formValid = formValid;
  }
}
export class prestadorForm {
  prestador: Prestador;
  formValid: boolean;

  constructor(prestador: Prestador, formValid: boolean) {
    this.prestador = prestador;
    this.formValid = formValid;
  }
}
export class transaccionForm {
  transaccion: Transacciones;
  formValid: boolean;

  constructor(transaccion: Transacciones, formValid: boolean) {
    this.transaccion = transaccion;
    this.formValid = formValid;
  }
}

//Models para ui

// export class UIState {
//   indexDetalleProcediminetoAEditar: number | null;
//   public loading: boolean;
//   public coordenadas: google.maps.LatLng;

//   constructor(
//     indexDetalleProcediminetoAEditar: number,
//     loading: boolean,
//     coordenadas: google.maps.LatLng
//   ) {
//     this.indexDetalleProcediminetoAEditar = indexDetalleProcediminetoAEditar;
//     this.loading = loading;
//     this.coordenadas = coordenadas;
//   }
// }

export class DatosIniciales {
  financiadores: Array<Financiador> | null;
  prestadores: Array<Prestador> | null;
  prestadoresSeleccionados: Array<PrestadorSelecionado> | null;
  tiposTransacciones: Array<codigoNombreModel> | null;
  tiposPrescripciones: Array<codigoNombreModel> | null;

  constructor(
    financiadores: Array<Financiador>,
    prestadores: Array<Prestador>,
    prestadoresSeleccionados: Array<PrestadorSelecionado>,
    tiposTransacciones: Array<codigoNombreModel>,
    tiposPrescripciones: Array<codigoNombreModel>
  ) {
    this.financiadores = financiadores;
    this.prestadores = prestadores;
    this.prestadoresSeleccionados = prestadoresSeleccionados;
    this.tiposTransacciones = tiposTransacciones;
    this.tiposPrescripciones = tiposPrescripciones;
  }
}

export class ConfiguracionUsuario { }

export class datosInicialesExtraState {
  public datosInicialesExtra: DatosInicialesExtra | null;

  constructor(datosInicialesExtra: DatosInicialesExtra) {
    this.datosInicialesExtra = datosInicialesExtra;
  }
}
export class ConfiguracionState {
  public codigosInstalacion: Array<codigoInstalacion> | null;
  public usuarioActual: UsuarioActualModel | null;
  public datosIniciales: DatosIniciales | null;
  public configuracionUsuario: ConfiguracionUsuario | null;

  constructor(
    codigosInstalacion: Array<codigoInstalacion> | null,
    usuarioActual: UsuarioActualModel | null,
    datosIniciales: DatosIniciales | null,
    configuracionUsuario: ConfiguracionUsuario | null
  ) {
    this.codigosInstalacion = codigosInstalacion;
    this.usuarioActual = usuarioActual;
    this.datosIniciales = datosIniciales;
    this.configuracionUsuario = configuracionUsuario;
  }
}
//Models para instalacion
export class InstalacionState {
  public usuarioInstalacion: InstalacionUsuario | null;
  public lugarAtencionSeleccionado: lugarAtencion | null;
  // public busquedaRealizada: searchReturn | null;
  public lugares_atencion: Array<lugarAtencion>;//modificacion Mica


  constructor(
    usuarioInstalacion: InstalacionUsuario,
    lugarAtencionSeleccionado: lugarAtencion,
    // busquedaRealizada: searchReturn,
    lugares_atencion: Array<lugarAtencion>,//modificacion Mica

  ) {
    this.usuarioInstalacion = usuarioInstalacion;
    this.lugarAtencionSeleccionado = lugarAtencionSeleccionado;
    // this.busquedaRealizada = busquedaRealizada;
    this.lugares_atencion = lugares_atencion; //modificacion Mica

  }
}

export class filterState {
  FilterTicketsRequest: TicketFiltrosModel | null;
  constructor(
    FilterTicketsRequest: TicketFiltrosModel
  ) {
    this.FilterTicketsRequest = FilterTicketsRequest
  }
}

export class sugerenciasState {
  SugerenciasRequest: SugerenciaModel | null;
  constructor(
    SugerenciasRequest: SugerenciaModel
  ) {
    this.SugerenciasRequest = SugerenciasRequest
  }
}

export class recetasState {
  RecetasRequest: Receta | null;
  constructor(
    RecetasRequest: Receta
  ) {
    this.RecetasRequest = RecetasRequest
  }
}

export class alternativeMedicamentState {
  AlternativeMedicamentRequest: any;
  constructor( AlternativeMedicamentRequest: any ) {
    this.AlternativeMedicamentRequest = AlternativeMedicamentRequest
  }
}

// esta es la clase que se usa para listar los lugares de atención que ya tiene cargado un prestador
export class InstalacionUsuario {
  public baja: boolean;
  public cuit: string;
  public id: number;
  public lugares_atencion: Array<lugarAtencion>;
  public pais: string;
  public razon_social: string;
  public tipo_prestador: string;

  constructor(
    baja: boolean,
    cuit: string,
    id: number,
    lugares_atencion: Array<lugarAtencion>,
    pais: string,
    razon_social: string,
    tipo_prestador: string
  ) {
    this.baja = baja;
    this.cuit = cuit;
    this.id = id;
    this.lugares_atencion = lugares_atencion;
    this.pais = pais;
    this.razon_social = razon_social;
    this.tipo_prestador = tipo_prestador;
  }
}

export class Droga {
  codigo: string;
  nombre: string;

  constructor(codigo: string, nombre: string) {
    this.codigo = codigo;
    this.nombre = nombre;
  }
}

export class Medicamento {
  troquel: string;
  precio: number;
  cod_barra: string;
  unidades: number;
  presentacion: string;
  nro_regis: string;
  nombre: string;
  unidad_unidades: string;
  cod_laboratorio: string;
  baja: boolean;
  droga: Droga[];
  snomed_ct_id?: string;
  snomed_term?: string;
  cantidad?: string;

  constructor(
    troquel: string,
    precio: number,
    cod_barra: string,
    unidades: number,
    presentacion: string,
    nro_regis: string,
    nombre: string,
    unidad_unidades: string,
    cod_laboratorio: string,
    baja: boolean,
    droga: Droga[],
    snomed_ct_id?: string,
    snomed_term?: string,
    cantidad?: string
  ) {
    this.troquel = troquel;
    this.precio = precio;
    this.cod_barra = cod_barra;
    this.unidades = unidades;
    this.presentacion = presentacion;
    this.nro_regis = nro_regis;
    this.nombre = nombre;
    this.unidad_unidades = unidad_unidades;
    this.cod_laboratorio = cod_laboratorio;
    this.baja = baja;
    this.droga = droga;
    this.snomed_ct_id = snomed_ct_id;
    this.snomed_term = snomed_term;
    this.cantidad = cantidad;
  }
}
