<div id="navbar" *ngIf="isElectron" class="flex justify-end	p-2 pr-4" >
  <div  class="flex gap-8 justify-end items-center" style="width: 10% !important;">

      <button (click)="minimizarAplicacion()" class="navbar-button flex justify-center items-center">
        <mat-icon svgIcon="heroicons_outline:minus" class="icon-size-4"></mat-icon>
      </button>

      <button (click)="maximizarAplicacion()" class="navbar-button  flex justify-center items-center">
        <mat-icon svgIcon="heroicons_outline:stop" class="icon-size-4"></mat-icon>
      </button>

      <button (click)="cerrarAplicacion()" class="navbar-button flex justify-center items-center">
        <mat-icon svgIcon="heroicons_outline:x-mark" class="icon-size-4"></mat-icon>
      </button>

  </div>
</div>

