import * as models from '../../store/models/store.models';
import * as datosTransaccionesActions from '../../store/actions/estadoFormsinicio.action';

const estadoInicial: models.estadoFormsState = {
  formEstadoCredencialRequest: null,
  formEstadoCodigoDeSeguridadRequest: null,
  formEstadoFinanciadorRequest: null,
  formEstadoPrestadorRequest: null,
  formEstadoTransaccionRequest: null,

};

export function estadoFormsReducer(
  state = estadoInicial,
  action: datosTransaccionesActions.estadoFormsActions
): models.estadoFormsState {
  switch (action.type) {
    case datosTransaccionesActions.SETESTADOFORMCREDENCIALREQUESTFORM:
      return {
        ...state,
        formEstadoCredencialRequest: action.formEstadoCredencialRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMCODIGODESEGURIDADREQUESTFORM:
      return {
        ...state,
        formEstadoCodigoDeSeguridadRequest: action.formEstadoCodigoDeSeguridadRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMFINANCIADORREQUEST:
      return {
        ...state,
        formEstadoFinanciadorRequest: action.formEstadoFinanciadorRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMPRESTADORREQUEST:
      return {
        ...state,
        formEstadoPrestadorRequest: action.formEstadoPrestadorRequest,
      };

    case datosTransaccionesActions.SETESTADOFORMTRANSACCIONREQUEST:
      return {
        ...state,
        formEstadoTransaccionRequest: action.formEstadoTransaccionRequest,
      };

      default:
      return state;
  }
}
