import { Injectable } from '@angular/core';
import { codigoInstalacion, usuarioEmail } from '../models/global.models';
import { UsuarioActualModel } from '../models/login.models';
import { ConfiguracionState } from '../store/models/store.models';
import { AppState } from 'app/store/app.reducers';
import { Store } from '@ngrx/store';
import { removeDatosUsuario, setUsuarioActual } from 'app/store/actions';

@Injectable({
  providedIn: 'root',
})
export class PersistenciaService {
  constructor(
    private store: Store<AppState>
  ) { }

  setLocal(key: string, data: any) {
    try {
      localStorage.setItem(key, data);
    } catch (e) {
      console.log('Error en storage: ', e);
    }
  }

  getLocal(key: string) {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return;
    }
  }

  getSession(key: string) {

    try {
      return localStorage.getItem(key);
    } catch (e) {
      return;
    }
  }

  getCurrentUser(): any{
    let currentUserResponse:any;
    let currentUser: any = localStorage.getItem('usuariosEmails');
    if (currentUser!= null) {
      currentUserResponse = JSON.parse(currentUser);
      currentUserResponse = new Array(currentUserResponse)[0][0]

    }
    return currentUserResponse;
  }

  //hidratate store (call app.component)
  hidratateStore(){
    if(this.getSession('usuariosEmails')){
      this.store.dispatch(new setUsuarioActual(this.getCurrentUser()));
    }
  }

  setCodigoInstalacion(
    arrayCodigosInstalacionAnterior: Array<codigoInstalacion>
  ) {
    let arrayAEnviar = [...arrayCodigosInstalacionAnterior];
    let arrayCodigosInstalacion = this.getCodigosInstalacion();
    if (arrayCodigosInstalacion) {
      arrayCodigosInstalacion.forEach((codigoInstalacion) => {
        if (
          arrayAEnviar.filter((codInstalacion) => codInstalacion.codigoInstalacion == codigoInstalacion.codigoInstalacion).length == 0) {
          arrayAEnviar.push(codigoInstalacion);
        }
      });
    }

    localStorage.setItem('codigosInstalacion', JSON.stringify(arrayAEnviar));
  }

  getCodigosInstalacion(): Array<codigoInstalacion> | null {
    let codigosInstalacion = localStorage.getItem('codigosInstalacion');
    let arrayCodigosInstalacion = null;
    if (codigosInstalacion) {
      arrayCodigosInstalacion = JSON.parse(
        codigosInstalacion
      ) as Array<codigoInstalacion>;
      if (arrayCodigosInstalacion.length == 0) {
        arrayCodigosInstalacion = null;
      }
    }
    return arrayCodigosInstalacion;
  }

  setUsuarioActual(usuarioActual: UsuarioActualModel) {
    this.removeusuarioActual();
    localStorage.setItem('usuarioActual', JSON.stringify(usuarioActual));
  }

  getusuarioActual(): UsuarioActualModel | null {
    let usuarioActualRepuesta = null;
    let usuarioActual = localStorage.getItem('usuarioActual');
    if (usuarioActual) {
      usuarioActualRepuesta = JSON.parse(usuarioActual) as UsuarioActualModel;
    }
    return usuarioActualRepuesta;
  }

  setEmailUsuarioActual(emailUsuarioActual: Array<usuarioEmail>) {
    // localStorage.setItem('usuariosEmails', JSON.stringify(emailUsuarioActual));
    let arrayAEnviar = [...emailUsuarioActual];
    let arrayEmailsUsuarios = this.getDatosUsuarioActual();
    if (arrayEmailsUsuarios) {
      arrayEmailsUsuarios.forEach((emails) => {
        if (
          arrayAEnviar.filter((userEmail) => userEmail.email == emails.email).length == 0) {
          arrayAEnviar.push(emails);
        }
      });
    }
    localStorage.setItem('usuariosEmails', JSON.stringify(arrayAEnviar));
  }

  getDatosUsuarioActual(): Array<usuarioEmail> | null {
    let usuariosEmails = localStorage.getItem('usuariosEmails');
    let arrayUsuarioActualRepuesta = null;
    if (usuariosEmails) {
      arrayUsuarioActualRepuesta = JSON.parse(usuariosEmails) as Array<usuarioEmail>;
    }
    if (arrayUsuarioActualRepuesta?.length == 0) {
      arrayUsuarioActualRepuesta = null
    }
    return arrayUsuarioActualRepuesta;
  }

  removeusuarioActual() {
    localStorage.removeItem('usuarioActual');
    this.store.dispatch(new removeDatosUsuario());
    // localStorage.removeItem('usuariosEmails');
  }

  removeInstalacion(codigoInstalacionAEliminar: string) {
    let arrayAEnviar: any[] = [];
    let arrayCodigosInstalacion = this.getCodigosInstalacion();
    if (arrayCodigosInstalacion) {
      arrayCodigosInstalacion.forEach((codigoInstalacion) => {
        if (codigoInstalacion.codigoInstalacion != codigoInstalacionAEliminar) {
          arrayAEnviar.push(codigoInstalacion);
        }
      });
      localStorage.removeItem('codigosInstalacion');
      localStorage.removeItem('usuarioActual');
      localStorage.setItem('codigosInstalacion', JSON.stringify(arrayAEnviar));
    }
  }

  recuperarDatos(): ConfiguracionState {
    return new ConfiguracionState(
      this.getCodigosInstalacion(),
      this.getusuarioActual(),
      null,
      null,
    );
  }
}
