import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as models from '../store/models/store.models';
import * as reducers from '../store/reducers';
import { State as MedicamentosState } from '../store/reducers/addMedicament.reducer';

export interface AppState {
  // ui: models.UIState;
  instalacion: models.InstalacionState;
  configuracion: models.ConfiguracionState;
  datosInicio: models.datosInicioState;
  datosTransaccion: models.datosTransaccionesState;
  filters: models.filterState;
  sugerencias: models.sugerenciasState;
  recetas: models.recetasState;
  estadoFormsInicio: models.estadoFormsState;
  alternativeMedicament: models.alternativeMedicamentState;
  datosInicioExtra: models.datosInicialesExtraState;
  addMedicaments: MedicamentosState;
}

export const appReducers: ActionReducerMap<AppState, any> = {
  // ui: reducers.uiReducer,
  instalacion: reducers.instalacionReducer,
  configuracion: reducers.configuracionReducer,
  datosInicio: reducers.datosInicioReducer,
  datosTransaccion: reducers.datosTransaccionesReducer,
  filters: reducers.filterReducer,
  sugerencias: reducers.sugerenciasReducer,
  recetas: reducers.recetasReducer,
  estadoFormsInicio: reducers.estadoFormsReducer,
  alternativeMedicament: reducers.alternativeMedicamentReducer,
  datosInicioExtra: reducers.datosInicialesExtraReducer,
  addMedicaments: reducers.addMedicamentReducer
};


export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function(state: AppState, action: Action): AppState {
      if (action.type === 'RESET_APP') {
        state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [clearState];