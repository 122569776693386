import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { DataUsuario } from 'app/core/user/user.types';
import { PersistenciaService } from 'app/services/persistencia.service';
import { removeDatosUsuario, unsetConfiguracion } from 'app/store/actions';
import { AppState } from 'app/store/app.reducers';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule],
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    private subscriptionStore: Subscription = new Subscription();
    @Input() showAvatar: boolean = true;
    user: DataUsuario;

    pharmacyEmail: string = '';

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private store: Store<AppState>,
        private persistenciaService: PersistenciaService,
        private authService: AuthService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: DataUsuario) => {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this.subscriptionStore = this.store.select('configuracion').subscribe((config) => {
            this.pharmacyEmail = config.usuarioActual.email;
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this.store.dispatch(new removeDatosUsuario());
        this.persistenciaService.removeusuarioActual();
        this._router.navigateByUrl('/sign-out');
    }



    goToConfiguration(): void {
        this._router.navigate(['/settings']);
    }
}
