import * as models from '../../store/models/store.models';
import * as recetas from '../../store/actions/recetas.action';

const estadoInicial: models.recetasState = {
  RecetasRequest: null,
};

export function recetasReducer(
  state = estadoInicial,
  action: recetas.recetasActions
): models.recetasState {
  switch (action.type) {
    case recetas.SETRECETASREQUEST:
      return {
        ...state,
        RecetasRequest:
          action.RecetasRequest,
      };

    default:
      return state;
  }
}
