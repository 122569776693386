import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppState } from 'app/store/app.reducers';
import { Store } from '@ngrx/store';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UsuarioActualModel } from 'app/models/login.models';
import { removeDatosUsuario, setUsuarioActual } from 'app/store/actions';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

declare const window: any;

@Component({
  selector: 'app-navbar-windows',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './navbar-windows.component.html',
  styleUrls: ['./navbar-windows.component.scss']
})
export class NavbarWindowsComponent {

  isLogin = false;
  mostrandoMenu: boolean = true;
  private subscriptionStore: Subscription = new Subscription();
  nombreUsuario: string | null = null;
  apellidoUsuario: string | null = null;
  domicilioUsuario: string | null = null;
  userActual!: UsuarioActualModel;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    public router: Router
  ) { }

  get isElectron(): boolean {
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
      // Electron-specific code
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptionStore.unsubscribe();
  }

  minimizarAplicacion() {
    window.api.electronIpcSend('minimizarAplicacion');
  }

  maximizarAplicacion() {
    window.api.electronIpcSend('maximizarAplicacion');
  }

  cerrarAplicacion() {
    window.api.electronIpcSend('cerrarAplicacion');
  }


}
