import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PersistenciaService } from './services/persistencia.service';
import { setDatosConfiguracion } from './store/actions';
import { AppState } from './store/app.reducers';
import { Store } from '@ngrx/store';
import { NavbarWindowsComponent } from './modules/components/navbar-windows/navbar-windows.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, NavbarWindowsComponent],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private persistenciaService: PersistenciaService,
        private store: Store<AppState>
    ) {
        this.store.dispatch(
            new setDatosConfiguracion(this.persistenciaService.recuperarDatos())
        );

        this.persistenciaService.hidratateStore();
    }
}
