/* eslint-disable */
export const faqCategories = [
    {
        id: '28924eab-97cc-465a-ba21-f232bb95843f',
        slug: 'mas-preguntado',
        title: 'Más preguntado',
    },
    // {
    //     id   : '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
    //     slug : 'general-inquiries',
    //     title: 'Consultas generales',
    // },
    // {
    //     id   : 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
    //     slug : 'licenses',
    //     title: 'Licencias',
    // },
    // {
    //     id   : '71c34043-d89d-4aca-951d-8606c3943c43',
    //     slug : 'payments',
    //     title: 'Payments',
    // },
    // {
    //     id   : 'bea49ee0-26da-46ad-97be-116cd7ab416d',
    //     slug : 'support',
    //     title: 'Soporte',
    // },
];
export const faqs = [
    // Most asked
    {
        id: 'f65d517a-6f69-4c88-81f5-416f47405ce1',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: '¿Cuáles son los costos asociados con Nuvalid Farmacia?',
        answer: ' ¡La buena noticia es que la aplicación es gratuita para las farmacias! No hay costos asociados con su uso. Nos comprometemos a ofrecer una solución accesible para todas las farmacias, sin comprometer la calidad del servicio. Puedes comenzar a disfrutar de todas las funcionalidades sin incurrir en gastos adicionales. ¡Explora las ventajas de nuestra aplicación sin preocuparte por el costo!',
    },
    {
        id: '0fcece82-1691-4b98-a9b9-b63218f9deef',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: '¿Cuáles son los beneficios clave de utilizar esta aplicación en una farmacia?',
        answer: 'Nuestra aplicación simplifica el proceso de recepción y gestión de recetas médicas, mejorando la eficiencia operativa. Los beneficios incluyen una reducción en los errores de procesamiento, mayor rapidez en la atención al cliente, acceso rápido al historial de recetas y la capacidad de coordinar de manera eficiente con los médicos. Además, proporcionamos actualizaciones regulares para garantizar la seguridad y la conformidad con las regulaciones.',
    },
    {
        id: '2e6971cd-49d5-49f1-8cbd-fba5c71e6062',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: '¿Cuáles son algunas de las funcionalidades destacadas de Nuvalid Farmacia?',
        answer: 'Nuvalid Farmacia proporciona una variedad de herramientas diseñadas para mejorar la eficiencia operativa de las farmacias. Estas incluyen funciones como el rescate de recetas general y por receta, la capacidad de anular recetas para realizar ajustes según sea necesario, el informe de consumo para un seguimiento detallado del inventario, la opción de consumo diferido para mayor flexibilidad en la gestión, un buscador de medicamentos para un acceso rápido a la información, el historial de tickets para un registro completo de transacciones pasadas, y la función de cierre de lote para facilitar la administración financiera. Estas funcionalidades combinadas están diseñadas para ofrecer una experiencia integral y mejorar la eficiencia en las operaciones diarias de la farmacia.',
    },
    {
        id: '974f93b8-336f-4eec-b011-9ddb412ee828',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question: '¿La aplicación es compatible con SifosClientesWeb?',
        answer: 'Sí, nuestra aplicación es totalmente compatible con SifosClienteWeb. Esto significa que puedes integrar y utilizar las funcionalidades de nuestra aplicación de manera armoniosa con tal sistema, garantizando una transición sin problemas y permitiéndote aprovechar al máximo todas las herramientas disponibles.',
    },
    // {
    //     id        : '5d877fc7-b881-4527-a6aa-d39d642feb23',
    //     categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
    //     question: '¿Cómo se garantiza la seguridad y privacidad de la información de las recetas médicas?',
    //     answer    : 'Labore mollit in aliqua exercitation aliquip elit nisi nisi voluptate reprehenderit et dolor incididunt cupidatat ullamco nulla consequat voluptate adipisicing dolor qui magna sint aute do excepteur in aliqua consectetur.\n\nElit laborum non duis irure ad ullamco aliqua enim exercitation quis fugiat aute esse esse magna et ad cupidatat voluptate sint nulla nulla lorem et enim deserunt proident deserunt consectetur.',
    // },
    // General inquiries
    // {
    //     id: '3d1c26c5-1e5e-4eb6-8006-ed6037ed9aca',
    //     categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
    //     question: 'How to download your items',
    //     answer: 'Sunt mollit irure dolor aliquip sit veniam amet ut sunt dolore cillum sint pariatur qui irure proident velit non excepteur quis ut et quis velit aliqua ea sunt cillum sit.\n\nReprehenderit est culpa ut incididunt sit dolore mollit in occaecat velit culpa consequat reprehenderit ex lorem cupidatat proident reprehenderit ad eu sunt sit ut sit culpa ea reprehenderit aliquip est.',
    // },
    // {
    //     id: '11bd2b9a-85b4-41c9-832c-bd600dfa3a52',
    //     categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
    //     question: 'View and download invoices',
    //     answer: 'Sint mollit consectetur voluptate fugiat sunt ipsum adipisicing labore exercitation eiusmod enim excepteur enim proident velit sint magna commodo dolor ex ipsum sit nisi deserunt labore eu irure amet ea.\n\nOccaecat ut velit et sint pariatur laboris voluptate duis aliqua aliqua exercitation et duis duis eu laboris excepteur occaecat quis esse enim ex dolore commodo fugiat excepteur adipisicing in fugiat.',
    // },
    // {
    //     id: 'f55c023a-785e-4f0f-b5b7-47da75224deb',
    //     categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
    //     question: 'I\'ve forgotten my username or password',
    //     answer: 'In exercitation sunt ad anim commodo sunt do in sunt est officia amet ex ullamco do nisi consectetur lorem proident lorem adipisicing incididunt consequat fugiat voluptate sint est anim officia.\n\nVelit sint aliquip elit culpa amet eu mollit veniam esse deserunt ex occaecat quis lorem minim occaecat culpa esse veniam enim duis excepteur ipsum esse ut ut velit cillum adipisicing.',
    // },
    // {
    //     id: 'c577a67d-357a-4b88-96e8-a0ee1fe9162e',
    //     categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
    //     question: 'Where is my license code?',
    //     answer: 'Ad adipisicing duis consequat magna sunt consequat aliqua eiusmod qui et nostrud voluptate sit enim reprehenderit anim exercitation ipsum ipsum anim ipsum laboris aliqua ex lorem aute officia voluptate culpa.\n\nNostrud anim ex pariatur ipsum et nostrud esse veniam ipsum ipsum irure velit ad quis irure tempor nulla amet aute id esse reprehenderit ea consequat consequat ea minim magna magna.',
    // },
    // {
    //     id: '1a680c29-7ece-4a80-9709-277ad4da8b4b',
    //     categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
    //     question: 'How to contact an author',
    //     answer: 'Magna laborum et amet magna fugiat officia deserunt in exercitation aliquip nulla magna velit ea labore quis deserunt ipsum occaecat id id consequat non eiusmod mollit est voluptate ea ex.\n\nReprehenderit mollit ut excepteur minim veniam fugiat enim id pariatur amet elit nostrud occaecat pariatur et esse aliquip irure quis officia reprehenderit voluptate voluptate est et voluptate sint esse dolor.',
    // },
    // {
    //     id: 'c49c2216-8bdb-4df0-be25-d5ea1dbb5688',
    //     categoryId: '395b0d41-b9a8-4cd6-8b5c-f07855e82d62',
    //     question: 'How does the affiliate program work?',
    //     answer: 'Adipisicing laboris ipsum fugiat et cupidatat aute esse ad labore et est cillum ipsum sunt duis do veniam minim officia deserunt in eiusmod eu duis dolore excepteur consectetur id elit.\n\nAnim excepteur occaecat laborum sunt in elit quis sit duis adipisicing laboris anim laborum et pariatur elit qui consectetur laborum reprehenderit occaecat nostrud pariatur aliqua elit nisi commodo eu excepteur.',
    // },
    // Licenses
    // {
    //     id: '3ef176fa-6cba-4536-9f43-540c686a4faa',
    //     categoryId: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
    //     question: 'How do licenses work for items I bought?',
    //     answer: 'Culpa duis nostrud qui velit sint magna officia fugiat ipsum eiusmod enim laborum pariatur anim culpa elit ipsum lorem pariatur exercitation laborum do labore cillum exercitation nisi reprehenderit exercitation quis.\n\nMollit aute dolor non elit et incididunt eiusmod non in commodo occaecat id in excepteur aliqua ea anim pariatur sint elit voluptate dolor eu non laborum laboris voluptate qui duis.',
    // },
    // {
    //     id: '7bc6b7b4-7ad8-4cbe-af36-7301642d35fb',
    //     categoryId: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
    //     question: 'Do licenses have an expiry date?',
    //     answer: 'Ea proident dolor tempor dolore incididunt velit incididunt ullamco quis proident consectetur magna excepteur cillum officia ex do aliqua reprehenderit est esse officia labore dolore aute laboris eu commodo aute.\n\nOfficia quis id ipsum adipisicing ipsum eu exercitation cillum ex elit pariatur adipisicing ullamco ullamco nulla dolore magna aliqua reprehenderit eu laborum voluptate reprehenderit non eiusmod deserunt velit magna do.',
    // },
    // {
    //     id: '56c9ed66-a1d2-4803-a160-fba29b826cb4',
    //     categoryId: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
    //     question: 'I want to make multiple end products with the same item',
    //     answer: 'Elit cillum incididunt enim cupidatat ex elit cillum aute dolor consectetur proident non minim eu est deserunt proident mollit ullamco laborum anim ea labore anim ex enim ullamco consectetur enim.\n\nEx magna consectetur esse enim consequat non aliqua nulla labore mollit sit quis ex fugiat commodo eu cupidatat irure incididunt consequat enim ut deserunt consequat elit consequat sint adipisicing sunt.',
    // },
    // {
    //     id: '21c1b662-33c8-44d7-9530-91896afeeac7',
    //     categoryId: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
    //     question: 'How easy is it to change the license type?',
    //     answer: 'Duis culpa ut veniam voluptate consequat proident magna eiusmod id est magna culpa nulla enim culpa mollit velit lorem mollit ut minim dolore in tempor reprehenderit cillum occaecat proident ea.\n\nVeniam fugiat ea duis qui et eu eiusmod voluptate id cillum eiusmod eu reprehenderit minim reprehenderit nisi cillum nostrud duis eu magna minim sunt voluptate eu pariatur nulla ullamco elit.',
    // },
    // {
    //     id: '5fa52c90-82be-41ae-96ec-5fc67cf054a4',
    //     categoryId: 'b388a87f-bfbb-44d0-800c-0ddbce2a5d22',
    //     question: 'Do I need a Regular License or an Extended License?',
    //     answer: 'Mollit nostrud ea irure ex ipsum in cupidatat irure sit officia reprehenderit adipisicing et occaecat cupidatat exercitation mollit esse in excepteur qui elit exercitation velit fugiat exercitation est officia excepteur.\n\nQuis esse voluptate laborum non veniam duis est fugiat tempor culpa minim velit minim ut duis qui officia consectetur ex nostrud ut elit elit nulla in consectetur voluptate aliqua aliqua.',
    // },
    // // Payments
    // {
    //     id: '81ac908c-35a2-4705-8d75-539863c35c09',
    //     categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
    //     question: 'Common PayPal, Skrill, and credit card issues',
    //     answer: 'Sit occaecat sint nulla in esse dolor occaecat in ea sit irure magna magna veniam fugiat consequat exercitation ipsum ex officia velit consectetur consequat voluptate lorem eu proident lorem incididunt.\n\nExcepteur exercitation et qui labore nisi eu voluptate ipsum deserunt deserunt eu est minim dolor ad proident nulla reprehenderit culpa minim voluptate dolor nostrud dolor anim labore aliqua officia nostrud.',
    // },
    // {
    //     id: 'b6d8909f-f36d-4885-8848-46b8230d4476',
    //     categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
    //     question: 'How do I find my transaction ID?',
    //     answer: 'Laboris ea nisi commodo nulla cillum consequat consectetur nisi velit adipisicing minim nulla culpa amet quis sit duis id id aliqua aute exercitation non reprehenderit aliquip enim eiusmod eu irure.\n\nNon irure consectetur sunt cillum do adipisicing excepteur labore proident ut officia dolor fugiat velit sint consectetur cillum qui amet enim anim mollit laboris consectetur non do laboris lorem aliqua.',
    // },
    // {
    //     id: '9496235d-4d0c-430b-817e-1cba96404f95',
    //     categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
    //     question: 'PayPal disputes And chargebacks',
    //     answer: 'Ullamco eiusmod do pariatur pariatur consectetur commodo proident ex voluptate ullamco culpa commodo deserunt pariatur incididunt nisi magna dolor est minim eu ex voluptate deserunt labore id magna excepteur et.\n\nReprehenderit dolore pariatur exercitation ad non fugiat quis proident fugiat incididunt ea magna pariatur et exercitation tempor cillum eu consequat adipisicing est laborum sit cillum ea fugiat mollit cupidatat est.',
    // },
    // {
    //     id: '7fde17e6-4ac1-47dd-a363-2f4f14dcf76a',
    //     categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
    //     question: 'Saving your credit card details',
    //     answer: 'Qui quis nulla excepteur voluptate elit culpa occaecat id ex do adipisicing est mollit id anim nisi irure amet officia ut sint aliquip dolore labore cupidatat magna laborum esse ea.\n\nEnim magna duis sit incididunt amet anim et nostrud laborum eiusmod et ea fugiat aliquip velit sit fugiat consectetur ipsum anim do enim excepteur cupidatat consequat sunt irure tempor ut.',
    // },
    // {
    //     id: '90a3ed58-e13b-40cf-9219-f933bf9c9b8f',
    //     categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
    //     question: 'Why do prepaid credits expire?',
    //     answer: 'Consequat consectetur commodo deserunt sunt aliquip deserunt ex tempor esse nostrud sit dolore anim nostrud nulla dolore veniam minim laboris non dolor veniam lorem veniam deserunt laborum aute amet irure.\n\nEiusmod officia veniam reprehenderit ea aliquip velit anim aute minim aute nisi tempor qui sunt deserunt voluptate velit elit ut adipisicing ipsum et excepteur ipsum eu ullamco nisi esse dolor.',
    // },
    // {
    //     id: '153376ed-691f-4dfd-ae99-e204a49edc44',
    //     categoryId: '71c34043-d89d-4aca-951d-8606c3943c43',
    //     question: 'Why is there a minimum $20 credit?',
    //     answer: 'Duis sint velit incididunt exercitation eiusmod nisi sunt ex est fugiat ad cupidatat sunt nisi elit do duis amet voluptate ipsum aliquip lorem aliqua sint esse in magna irure officia.\n\nNon eu ex elit ut est voluptate tempor amet ut officia in duis deserunt cillum labore do culpa id dolore magna anim consectetur qui consectetur fugiat labore mollit magna irure.',
    // },
    // Support
    // {
    //     id: '4e7ce72f-863a-451f-9160-cbd4fbbc4c3d',
    //     categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
    //     question: 'What is item support?',
    //     answer: 'Exercitation sit eiusmod enim officia exercitation eiusmod sunt eiusmod excepteur ad commodo eiusmod qui proident quis aliquip excepteur sit cillum occaecat non dolore sit in labore ut duis esse duis.\n\nConsequat sunt voluptate consectetur dolor laborum enim nostrud deserunt incididunt sint veniam laboris sunt amet velit anim duis aliqua sunt aliqua aute qui nisi mollit qui irure ullamco aliquip laborum.',
    // },
    // {
    //     id: '0795a74f-7a84-4edf-8d66-296cdef70003',
    //     categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
    //     question: 'How to contact an author',
    //     answer: 'Minim commodo cillum do id qui irure aliqua laboris excepteur laboris magna enim est lorem consectetur tempor laboris proident proident eu irure dolor eiusmod in officia lorem quis laborum ullamco.\n\nQui excepteur ex sit esse dolore deserunt ullamco occaecat laboris fugiat cupidatat excepteur laboris amet dolore enim velit ipsum velit sint cupidatat consectetur cupidatat deserunt sit eu do ullamco quis.',
    // },
    // {
    //     id: '05532574-c102-4228-89a8-55fff32ec6fc',
    //     categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
    //     question: 'Extending and renewing item support',
    //     answer: 'Reprehenderit anim consectetur anim dolor magna consequat excepteur tempor enim duis magna proident ullamco aute voluptate elit laborum mollit labore id ex lorem est mollit do qui ex labore nulla.\n\nUt proident elit proident adipisicing elit fugiat ex ullamco dolore excepteur excepteur labore laborum sunt ipsum proident magna ex voluptate laborum voluptate sint proident eu reprehenderit non excepteur quis eiusmod.',
    // },
    // {
    //     id: 'b3917466-aa51-4293-9d5b-120b0ce6635c',
    //     categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
    //     question: 'Rating or review removal policy',
    //     answer: 'Ipsum officia mollit qui laboris sunt amet aliquip cupidatat minim non elit commodo eiusmod labore mollit pariatur aute reprehenderit ullamco occaecat enim pariatur aute amet occaecat incididunt irure ad ut.\n\nIncididunt cupidatat pariatur magna sint sit culpa ad cupidatat cillum exercitation consequat minim pariatur consectetur aliqua non adipisicing magna ad nulla ea do est nostrud eu aute id occaecat ut.',
    // },
    // {
    //     id: '2f2fb472-24d4-4a00-aa80-d513fa6c059c',
    //     categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
    //     question: 'Purchasing supported and unsupported items',
    //     answer: 'Dolor cupidatat do qui in tempor dolor magna magna ut dolor est aute veniam consectetur enim sunt sunt duis magna magna aliquip id reprehenderit dolor in veniam ullamco incididunt occaecat.\n\nId duis pariatur anim cillum est sint non veniam voluptate deserunt anim nostrud duis voluptate occaecat elit ut veniam voluptate do qui est ad velit irure sint lorem ullamco aliqua.',
    // },
    // {
    //     id: '2fffd148-7644-466d-8737-7dde88c54154',
    //     categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
    //     question: 'I haven\'t received a response from the author',
    //     answer: 'Velit commodo pariatur ullamco elit sunt dolor quis irure amet tempor laboris labore tempor nisi consectetur ea proident dolore culpa nostrud esse amet commodo do esse laboris laboris in magna.\n\nAute officia labore minim laborum irure cupidatat occaecat laborum ex labore ipsum aliqua cillum do exercitation esse et veniam excepteur mollit incididunt ut qui irure culpa qui deserunt nostrud tempor.',
    // },
    // {
    //     id: '24a1034e-b4d6-4a86-a1ea-90516e87e810',
    //     categoryId: 'bea49ee0-26da-46ad-97be-116cd7ab416d',
    //     question: 'Responding to requests outside of support',
    //     answer: 'Exercitation eu in officia lorem commodo pariatur pariatur nisi consectetur qui elit in aliquip et ullamco duis nostrud aute laborum laborum est dolor non qui amet deserunt ex et aliquip.\n\nProident consectetur eu amet minim labore anim ad non aute duis eiusmod sit ad elit magna do aliquip aliqua laborum dolor laboris ea irure duis mollit fugiat tempor eu est.',
    // },
];
export const guideCategories = [
    {
        id: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug: 'comenzando',
        title: 'Comenzando',
    },
    {
        id: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'recetas',
        title: 'Recetas',
    },
    {
        id: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug: 'configuracion',
        title: 'Configuración',
    },
    {
        id: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug: 'tickets',
        title: 'Tickets',
    },
    {
        id: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'informacion-legal',
        title: 'Información Legal',
    },
];
export const guides = [
    // comenzando
    {
        id: 'a008ffa3-7b3f-43be-8a8f-dbf5272ed2dd',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug: 'que-es-nuvalid-farmacia',
        title: '¿Qué es Nuvalid Farmacia?',
        subtitle: 'Tu aliado para gestionar recetas digitales de manera fácil y eficiente.',
        content: `<p><strong>Nuestra aplicación para farmacias ofrece una solución integral y de vanguardia para la gestión eficiente y segura de recetas digitales, control de consumos de medicamentos y análisis detallado de transacciones. Diseñada para satisfacer las necesidades específicas del sector farmacéutico, nuestra plataforma proporciona herramientas poderosas que optimizan las operaciones diarias y mejoran la experiencia tanto para los farmacéuticos como para los pacientes.</p> `,
    },
    {
        id: '1fecee67-c4b4-413a-b0f2-949dcab73249',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug: 'instalar-nuvalid-farmacia',
        title: 'Instalar Nuvalid Farmacia',
        subtitle: 'Instrucciones para instalar la aplicación de farmacia en un dispositivo.',
        content: `<h2>Procedimiento Instalación</h2>
                    <p>Los procedimientos para instalar Nuvalid Farmacia son los siguientes:</p>
                    <ol>
                        <li>Podrá descargar Nuvalid Farmacia desde su <a href="https://apps.microsoft.com/detail/xp9k0clphkj38s?hl=es-es&gl=AR" target="_blank">Microsoft Store</a>
                          y además ingresando a los siguientes enlaces de descarga:
                          <a href="https://descargas.itcsoluciones.com/FarmaciaSetup.exe" target="_blank">Descarga 64bits</a> -
                          <a href="https://descargas.itcsoluciones.com/FarmaciaSetup32bits.exe" target="_blank">Descarga 32bits</a>
                          </li>
                        <li>Posteriormente, introduzca el CUIT del domicilio donde desea instalar Nuvalid Farmacia. <span class="italic">Este CUIT debe haber sido previamente dado de alta en nuestro sistema. </span>
                    </li>
                    <figure class="sm:w-5/6">
                        <img class="w-full h-auto" src="../../../../assets/images/guides/inst/cuit.png" alt="Instalación Nuvalid Farmacia">
                    </figure>
                    <li>Una vez validado, podrá visualizar el listado de domicilios que se encuentran habilitados, seleccione donde utilizará la plataforma.</li>
                     <figure class="sm:w-5/6">
                        <img class="w-full h-auto" src="../../../../assets/images/guides/inst/domicilios.png" alt="Instalación Nuvalid Farmacia">
                    </figure>
                    <li>Por último, verifique que toda la información ingresada sea correcta. La próxima vista que visualizará es la de Ingreso y Registro</li>
                    </ol>
`,
    },
    {
        id: 'd2e2ea8f-5298-4ba2-898b-afc60c064bba',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug: 'autenticacion-nuvalid-farmacia',
        title: 'Autenticación en Nuvalid Farmacia',
        subtitle: 'Guía para acceder y registrarte en Nuvalid Farmacia.',
        content: `<h2>Iniciar Sesión</h2>
                    <p>Después de completar la instalación, podrá iniciar sesión. Si ya tiene una cuenta, simplemente ingrese el domicilio donde desea utilizar la plataforma, junto con su correo electrónico y contraseña.</p>
                    <figure class="sm:w-5/6">
                    <img class="w-full" src="../../../../assets/images/guides/aut/signIn.png" alt="Inicio Sesión Nuvalid Farmacia">
                    </figure>
                    <h2>Registro</h2>
                    <p>Si no dispone de una cuenta, para registrarse deberá completar todos los campos requeridos. Posteriormente, recibirá un correo electrónico de confirmación en su bandeja de entrada. Una vez confirmado, podrá acceder a Nuvalid Farmacia.</p>
                    <figure class="sm:w-5/6">
                        <img class="w-full" src="../../../../assets/images/guides/aut/signUp.png" alt="Registro Nuvalid Farmacia">
                    </figure>`,
    },
    {
        id: '7643d388-12ab-4025-a2f1-5045ac7b1c4c',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug: 'comience-a-usar-nuvalid-farmacia',
        title: 'Comience a usar Nuvalid Farmacia',
        subtitle: 'Instructivo inicial para empezar a utilizar la aplicación.',
        content: `<h2>Interfaz</h2>
        <p> La interfaz de Nuvalid Farmacia es altamente intuitiva y le permite realizar diversas funciones de manera eficiente. Las funcionalidades están organizadas en tres categorías principales</p>
        <ul>
            <li> <strong>Inicio</strong>: Aquí encontrará todas las herramientas relacionadas con las recetas, facilitando su gestión.</li>
            <figure class="sm:w-5/6">
                <img class="w-full" src="../../../../assets/images/guides/interfaz/inicio.png" alt="Inicio Nuvalid Farmacia">
            </figure>
            <li><strong>Tickets</strong>: Esta sección engloba todas las funciones relacionadas con tickets, historiales y cierres, ofreciendo un acceso fácil y rápido a esta información.</li>
           <figure class="sm:w-5/6">
                <img  class="w-full" src="../../../../assets/images/guides/interfaz/tickets.png" alt="Tickets Nuvalid Farmacia">
            </figure>
            <li><strong>Configuración</strong>: En esta sección puede configurar todos los aspectos necesarios de la plataforma y sus datos personales para adaptarla a sus necesidades específicas. </li>
            <figure class="sm:w-5/6">
            <img class="w-full" src="../../../../assets/images/guides/interfaz/configuracion.png" alt="Configuración Nuvalid Farmacia">
            </figure>
        </ul>
         <p>Además, cuenta con una sección de guías que le brindarán asistencia en caso de que necesite ayuda con alguna funcionalidad específica.</p>`,
    },

    // {
    //     id: 'd2e2ea8f-5298-4ba2-898b-afc60c064bba',
    //     categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
    //     slug: 'navigating-within-the-app',
    //     title: 'Registrarse en Nuvalid Farmacia',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // recetas
    {
        id: 'f2592886-11b8-4b56-baab-96802c2ed93e',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'rescatar-recetas',
        title: 'Rescatar recetas',
        subtitle: 'Guía rápida e intuitiva para aprender a trabajar con recetas en Nuvalid Farmacia.',
        content: `<h1>¿Cómo rescatar recetas?</h1>
                    <p>Para recuperar una receta, necesitarás ingresar el número de afiliado y, si es necesario, especificar la farmacia donde deseas realizar la operación. Si los datos son precisos, se imprimirán todas las recetas digitales asociadas a esa credencial. Además, tendrás la posibilidad de realizar varias operaciones para ese usuario, como informar consumo, anular e informar consumo diferido.<p>
                    <figure class="sm:w-5/6">
                        <img class="w-full" src="../../../../assets/images/guides/rescate/vista_1.png" alt="Rescatar Recetas Nuvalid Farmacia">
                    </figure>
                    <h2>Rescatar receta particular</h2>
                    <p>Si deseas visualizar una receta en particular y trabajar con la misma solo deberás realizar click sobre la misma, se desplegará una ventana con toda su información, donde podrás informar su consumo, cambiar y/o eliminar medicamentos, así como agregar nuevos.</p>

                      <p> Una vez hayas cargado todos los medicamentos necesarios y verificado la información, debes consumir la receta haciendo clic en el botón "Informar consumo". Luego, ingresarás el código de seguridad correspondiente para finalizar la operación.</p>
                    <figure class="sm:w-5/6">
                        <img class="w-full" src="../../../../assets/images/guides/rescate/vista_2.png" alt="Rescatar Receta Particular Nuvalid Farmacia">
                    </figure>
                    
                    <h2>Tipos de prescripciones</h2>


                    <ul>
                    
                        <li><strong>Prescripción Genérica</strong> esta viene con la monodroga cargada pero sin un medicamento específico seleccionado. Por lo tanto, para poder informar el consumo, deberá seleccionar un medicamento específico. Sin completar esta acción, no será posible informar el consumo.</li>
                         <figure class="sm:w-5/6">
                        <img class="w-full" src="../../../../assets/images/guides/rescate/selectMedicamento.png" alt="Seleccionar Medicamento Nuvalid Farmacia">
                        </figure>
                        <li><strong>Prescripción Comercial</strong>: los medicamentos pueden ser reemplazados, siempre y cuando coincidan con la monodroga requerida. Estas sustituciones serán consideradas como "recomendaciones" del farmacéutico. </li>
                        <li><strong>Prescripción Comercial Justificado</strong>: no se permite modificar los medicamentos cargados. Sin embargo, sí es posible agregar y eliminar medicamentos en esta categoría.</li>
                    </ul>

                      <h3>Agregar medicamento</h3>
                      <p>Desde el botón "Agregar medicamento", podrás cargar el medicamento especificando su número de troquel y/o código de barras. También deberás ingresar la cantidad y el tipo de prescripción correspondiente. En caso de no tener esta información, podrás buscar el medicamento por su nombre y/o droga. Además, tienes la opción de filtrar por su presentación en caso de que sea necesario.</p>
                    <figure class="sm:w-5/6">
                        <img class="w-full" src="../../../../assets/images/guides/rescate/vista_3.png" alt="Agregar Medicamento Nuvalid Farmacia">
                    </figure>
                      <h3>Modificar medicamento</h3>
                      <p>La funcionalidad para modificar un medicamento es similar a la de agregar un medicamento, pero con la diferencia de que la modificación del medicamento se realiza directamente en la tabla donde se muestran todos los medicamentos según su clasificación por tipo de prescripción. En esta tabla, podrás realizar las modificaciones necesarias sobre el medicamento seleccionado.</p>`,
    },
    // {
    //     id: '9ec3f4b9-a355-4f57-9e93-efa8611cc1c9',
    //     categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
    //     slug: 'anular-recetas',
    //     title: 'Anular Receta',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    {
        id: '1bc6e7f9-b046-4f4f-9b18-741c9d5429f6',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug: 'operaciones',
        title: 'Operaciones',
        subtitle: 'Instructivo para aprender a utilizar las diferentes operaciones de manera efectiva.',
        content: `<h2>Informar consumo</h2>
                    <p>Desde esta sección, podrás ingresar una receta no digital. Se te solicitará el número de la receta, su fecha, el número de matrícula del médico y su tipo (nacional o provincial), indicando si es médico u odontólogo. Una vez ingresados estos datos, podrás trabajar con la receta como si fuera digital, es decir, agregar, editar o eliminar medicamentos. Posteriormente, podrás realizar el consumo de la receta.</p>
                     <figure>
                         <img src="../../../../assets/images/guides/ope/vista_IC.png" alt="Informar Consumo Nuvalid Farmacia">
                    </figure>
                <h2>Informar consumo diferido</h2>
                 <p>Desde esta funcionalidad, podrás cargar las recetas con fecha diferida, ya sean digitales o no. Para comenzar, deberás ingresar el número de receta, su fecha, el número de matrícula del médico y el tipo de matrícula (nacional o provincial). Además, se te solicitará si es médico u odontólogo, y por último, la fecha y hora de cuando se consumió la misma. Una vez hecho esto, la visualización y las funcionalidades serán las mismas que las de rescatar una receta digital. Es decir, podrás acceder a la información de la receta y realizar las operaciones necesarias, como informar consumo o modificar medicamentos, según sea necesario.</p>
                  <figure>
                        <img src="../../../../assets/images/guides/ope/vista_ICD.png" alt="Informar Consumo Diferido Nuvalid Farmacia">
                    </figure>
                  <p>Al momento de agregar o editar un medicamento, también se te pedirá que indiques el tipo de cobertura para aplicar el descuento correspondiente. Los tipos de cobertura disponibles son los siguientes:</p>
                <ul>   
                <li><strong>Cobertura normal</strong>:Esto implica un descuento del 40% en el medicamento.</li>
                <li><strong>PMI (Programa Médico Obligatorio)</strong>:Este tipo de cobertura cubre el 100% del costo del medicamento.</li>
                <li><strong>Cobertura crónica</strong>:Aquí se aplica un descuento del 70% en el medicamento.</li>
                </ul>
                <h2>Anular consumo</h2>
                <p>En caso de necesitar retractar una operación de consumo, podrás realizar esta acción desde este panel. Simplemente haz clic sobre el botón "Anulación" e ingresa el número de ticket correspondiente a la operación ya consumida.</p>
                <figure class="sm:w-8/12">
                        <img class="w-full" src="../../../../assets/images/guides/ope/anular.png" alt="Anular Receta Nuvalid Farmacia">
                </figure>


                  
`,
    },
    // {
    //     id: 'a005d5f1-938d-45c5-8ed4-d0cf8d02e533',
    //     categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
    //     slug: 'informar-consumo-diferido',
    //     title: 'Informar consumo diferido',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // {
    //     id: '43837279-dce2-4dc0-beac-30b5ba829f14',
    //     categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
    //     slug: 'cambiar-eliminar-medicamento',
    //     title: 'Cambiar y/o eliminar medicamento',
    //     subtitle: 'Instructivo para aprender a modificar o eliminar medicamentos comerciales y genéricos.',
    // },
    // {
    //     id: '4cf5a435-eaa0-463c-8d2b-efde193c7fb3',
    //     categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
    //     slug: 'adding-media-to-a-project',
    //     title: 'Adding media to a project',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // {
    //     id: 'cd3fb87e-e138-4721-9e29-a5c751bfd949',
    //     categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
    //     slug: 'removing-a-media-from-a-project',
    //     title: 'Removing a media from a project',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // {
    //     id: 'f26205c6-882e-4713-b067-c73758b45551',
    //     categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
    //     slug: 'cropping-a-media',
    //     title: 'Cropping a media',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // Configuracion
    {
        id: '1cbdeaeb-bbf1-4d04-b43d-f37b55e6a229',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug: 'configuracion',
        title: 'Configuración',
        subtitle: 'Podrás actualizar la información de tu cuenta, incluyendo la contraseña, de manera sencilla.',
        content: `<p>Desde esta sección, tendrás la capacidad de ajustar la información vinculada a tu cuenta. Además, dependiendo de tu función o rol, podrás gestionar los aspectos pertinentes a tu farmacia.</p>
        <p>La sección cuenta con 4 subsecciones:</p>
        <ul>
            <li><strong>Cuenta</strong>:Desde aquí podrás realizar cambios en tu nombre, apellido y correo electrónico donde recibes las notificaciones de Nuvalid Farmacia.</li>
            <figure class="sm:w-5/6">
            <img class="w-full" src="../../../../assets/images/guides/conf/cuenta.png" alt="Cuenta Nuvalid Farmacia">
            </figure>
            <li><strong>Seguridad</strong>:En esta sección podrás gestionar el cambio de tu contraseña para garantizar la seguridad de tu cuenta.</li>
             <figure class="sm:w-5/6">
             <img class="w-full" src="../../../../assets/images/guides/conf/seguridad.png" alt="Seguridad Nuvalid Farmacia">
            </figure>
            <li><strong>Usuarios</strong>: Aquí podrás visualizar todos los usuarios activos y desactivarlos si es necesario. También tendrás acceso a un historial de usuarios deshabilitados y podrás activarlos nuevamente según lo requieras.</li>
        </ul>

            <h3>Roles</h3>
            <p>Actualmente, existen dos roles en el sistema: <strong>Administrador</strong> y <strong>Farmacéutico</strong>.</p>
            <p>El rol de <strong>Administrador</strong> se asigna inicialmente a la persona que instala por primera vez la aplicación. A diferencia del <strong>Farmacéutico</strong>, el Administrador tiene la capacidad de gestionar la sección de equipo (team).
            Esto significa que el Administrador puede realizar acciones relacionadas con la gestión de usuarios, como visualizar todos los usuarios activos, desactivarlos, ver el historial de usuarios deshabilitados y activarlos nuevamente según sea necesario. Por otro lado, el farmacéutico no tiene acceso a estas funciones y solo puede realizar acciones relacionadas con su propio perfil y operaciones dentro de la aplicación.
            </p>
            <figure class="sm:w-5/6">
            <img class="w-full" src="../../../../assets/images/guides/conf/usuarios.png" alt="Usuarios Nuvalid Farmacia">
            </figure>

`
    },
    // {
    //     id: '98de7d4a-2ca2-4d47-bbe6-083ed26467db',
    //     categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
    //     slug: 'cambiar-contraseña',
    //     title: 'Cambiar contraseña',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // {
    //     id: '145f497c-1fdb-47b5-a6c1-31f856403571',
    //     categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
    //     slug: 'team',
    //     title: 'Team',
    //     subtitle: 'Podrás administrar los perfiles con los distintos roles que les hayas asignado.',
    // },
    // {
    //     id: '0a007f59-a5ea-4875-991d-f22d6fd69898',
    //     categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
    //     slug: 'domain-settings',
    //     title: 'Domain settings',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // {
    //     id: '4707c8eb-31f9-415c-bd07-86f226c75feb',
    //     categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
    //     slug: 'privacy-settings',
    //     title: 'Privacy settings',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // tickets
    {
        id: 'c771bf0a-1e0c-4b6d-af7e-189e10cc6fb8',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug: 'revisar-historial-tickets',
        title: 'Historial de Tickes',
        subtitle: 'Descubre cómo revisar todo tu historial de tickets y transacciones, aprovechando al máximo las funcionalidades disponibles.',
        content: `<p>Desde esta sección, podrás llevar a cabo diversas gestiones relacionadas con los tickets de las recetas que ya han sido consumidas anteriormente en Nuvalid Farmacia. Para comenzar, deberás ingresar un rango de fechas específico. Además, si es necesario para un filtrado más preciso, tendrás la opción de ingresar el financiador, la farmacia y el afiliado correspondientes.</p>
        <figure  class="sm:w-5/6">
            <img  class="w-full" src="../../../../assets/images/guides/tickets/tickets.png" alt="Tickets Nuvalid Farmacia">
        </figure>
        <h3>Consulta de tickets</h3>
        <p>Esta funcionalidad te permite llevar a cabo una supuesta liquidación de todos los consumos realizados, para luego presentarla ante tu financiador. Deberás ingresar un rango de fechas y seleccionar la farmacia cuyo cierre deseas visualizar.</p>`
    },
    // {
    //     id: '3d7150d2-feb3-4f20-bd3f-8e525cef77a4',
    //     categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
    //     slug: 'cierre-lote-tickets',
    //     title: 'Cierre de Lote Tickets',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // {
    //     id: '79239bc4-4fb5-428b-b30d-62c5289b061d',
    //     categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
    //     slug: 'payment-methods',
    //     title: 'Payment methods',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // {
    //     id: '8d68c5e6-5404-450c-9d5f-d9800c164041',
    //     categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
    //     slug: 'overdue-payments',
    //     title: 'Overdue payments',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // Your account
    {
        id: '60df0d4c-dda1-439c-bd44-179c57a7597d',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'informacion-legal-Nuvalid',
        title: 'Información Legal sobre NUVALID',
        subtitle: '',
        content: `<p>El respeto por su privacidad es nuestra prioridad. Hemos desarrollado NUVALID con un conjunto de principios de privacidad sólidos en mente. En nuestros <a href="help-center/guides/informacion-legal/terminos-servicio-Nuvalid"  class="link">Términos</a> actualizados y <a href="help-center/guides/informacion-legal/politica-privacidad-Nuvalid" class="link">Política de privacidad </a>encontrará:</p>
        <ul>
            <li><span class="italic">Información que es fácil de entender.</span>Hemos tratado de mantener un mensaje claro y simple, pero estamos a tú disposición para cualquier consulta.</li>
            <li><span class="italic">Tú información es tuya.</span>No compartimos tus datos con terceros, solo transportamos tus solicitudes de autorización a quienes nos indicás cuando seleccionas un financiador.</li>
            <li><span class="italic">No hay anuncios o publicidad de terceros.</span>Por ahora no encontrarás ningún tipo de publicidad en la aplicación.</li>
        </ul>
`,
    },
    {
        id: '0a9c3321-1db3-42bc-92b6-7e257368123e',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'terminos-servicio-Nuvalid',
        title: 'Términos de servicio de NUVALID',
        subtitle: '',
        content: `<p>Última modificación: 25 de noviembre de 2022</p>
        <p>ITC Soluciones ("ITC", "nuestro" o "nosotros") proporciona Nuvalid que es una herramienta de gestión administrativa para profesionales médicos.</p>
        <h2>Acerca de nuestros servicios</h2>
        <ul>
            <li><strong>Registro. </strong>Debe registrarse para poder usar nuestros Servicios utilizando datos precisos, proporcionando número de CUIT o clave fiscal, y su correo eléctrónico.</li>
            <li><strong>Directorio. </strong>Usted nos proporciona acceso a sus otros contactos en su libreta de direcciones de teléfonos móviles de forma regular de manera de poder cargar datos de su paciente en forma rápida. Usted confirma que está autorizado a proporcionarnos dichos números para permitirnos proporcionar nuestros servicios.</li>
            <li><strong>Años. </strong>Usted debe tener por lo menos 13 años de edad para usar nuestros Servicios (o tal edad mayor requerida en su país para que usted esté autorizado a usar nuestros Servicios sin la aprobación de los padres). Además de ser de la edad mínima requerida para usar nuestros Servicios bajo la ley aplicable, si usted no tiene la edad suficiente para tener autoridad para aceptar nuestros Términos en su país, su padre o guardián debe aceptar nuestros Términos en su nombre.</li>
            <li><strong>Dispositivos y Software. </strong>Debe proporcionar determinados dispositivos, software y conexiones de datos para utilizar nuestros Servicios, que de lo contrario no proporcionamos. Mientras usted use nuestros Servicios, usted consiente en descargar e instalar actualizaciones a nuestros Servicios, incluyendo automáticamente.</li>
            <li><strong>Tarifas e impuestos. </strong> Usted es responsable de todos los planes de datos del transportista y de otras tarifas e impuestos asociados con el uso de nuestros Servicios. Podemos cobrarle por nuestros servicios, incluidos los impuestos aplicables. Podemos rechazar o cancelar órdenes. No proporcionamos reembolsos para nuestros servicios, excepto según lo requerido por la ley.</li>

        </ul>

        <h2>Política de privacidad y datos de usuario</h2>
         <p>La Política de privacidad describe nuestras prácticas de información (incluido el mensaje), incluidos los tipos de información que recibimos y recopilamos de usted y cómo usamos y compartimos esta información. Usted acepta nuestras prácticas de datos, incluyendo la recopilación, uso, procesamiento y uso compartido de su información como se describe en nuestra Política de Privacidad, así como la transferencia y procesamiento de su información a Argentina y otros países en el mundo donde tenemos o utilizamos Instalaciones, proveedores de servicios o socios, independientemente de dónde utilice nuestros Servicios. Usted reconoce que las leyes, reglamentos y normas del país en el que se almacena o procesa su información pueden ser diferentes de los de su propio país.</p>

        <h2>Uso aceptable de nuestros servicios</h2>
        <ul>
            <li><strong>Nuestros Términos y Políticas.</strong>Debe utilizar nuestros Servicios de acuerdo con nuestros Términos y políticas publicadas. Si inhabilitamos su cuenta por una infracción de nuestros Términos, no creará otra cuenta sin nuestro permiso.</li>
            <li><strong>Uso Legal y Aceptable.</strong>Usted debe tener acceso y utilizar nuestros Servicios sólo para propósitos legales, autorizados y aceptables. Usted no usará (o ayudará a otros en el uso) nuestros Servicios de manera que: (a) infrinja, abuse o infrinja los derechos de ITC soluciones, nuestros usuarios u otros, incluyendo privacidad, publicidad, propiedad intelectual u otros derechos de propiedad; (B) sean ilegales, obscenas, difamatorias, amenazantes, intimidantes, hostiles, odiosas, racialmente o étnicamente ofensivas, o instiguen o estimulen conductas que serían ilegales o inapropiadas, incluyendo la promoción de crímenes violentos; (C) implicar la publicación de falsedades, declaraciones falsas o declaraciones engañosas; (D) suplantar a alguien; (E) implicar el envío de comunicaciones ilegales o no admisibles tales como mensajes masivos, mensajería automática, marcación automática y similares; O (f) involucrar cualquier uso no personal de nuestros Servicios a menos que seamos autorizados por nosotros.</li>
            <li><strong>Daño a ITC Soluciones o a nuestros usuarios. </strong>Usted no debe (ni ayudar a otros a) acceder, usar, copiar, adaptar, modificar, preparar trabajos derivados basados ​​en, distribuir, licenciar, sublicenciar, transferir, exhibir, realizar o explotar de alguna manera nuestros Servicios de manera inadmisible o no autorizada. Formas de carga, perjuicio o perjuicio para nosotros, nuestros Servicios, sistemas, nuestros usuarios u otros, incluyendo que usted no debe directamente o por medios automatizados: (a) hacer ingeniería inversa, alterar, modificar, crear obras derivadas, descompilar o Extraer código de nuestros Servicios; (B) enviar, almacenar o transmitir virus u otro código informático dañino a través de o sobre nuestros Servicios; (C) ganar o intentar obtener acceso no autorizado a nuestros Servicios o sistemas; (D) interferir o interrumpir la integridad o desempeño de nuestros Servicios; (E) crear cuentas para nuestros Servicios a través de medios no autorizados o automatizados; (F) recopilar la información de nuestros usuarios o sobre ellos de cualquier manera no permitida o no autorizada; (G) vender, revender, alquilar o cobrar por nuestros Servicios; O (h) distribuir o hacer que nuestros Servicios estén disponibles a través de una red donde puedan ser utilizados por múltiples dispositivos al mismo tiempo.</li>
            <li><strong>Mantener su cuenta segura. </strong>Usted es responsable de mantener su dispositivo y su cuenta de NUVALID segura, y debe notificarnos sin demora de cualquier uso no autorizado o violación de seguridad de su cuenta o de nuestros Servicios.</li>
        </ul>

        <h2>Licencias</h2>
        <ul>
        <li><strong>Tus derechos.</strong>ITC Soluciones no ​​reclama la propiedad de la información que envía para su cuenta de NUVALID. Usted debe tener los derechos necesarios a tal información que usted somete para su cuenta de NUVALID o a través de nuestros Servicios y el derecho de conceder los derechos y licencias en nuestros Términos.</li>
        <li><strong>Los derechos de ITC Soluciones.</strong>Somos propietarios de todos los derechos de autor, marcas registradas, dominios, logotipos, secretos comerciales, patentes y otros derechos de propiedad intelectual relacionados con nuestros servicios. Usted no puede usar nuestros derechos de autor, marcas registradas, dominios, logotipos, vestimenta, patentes y otros derechos de propiedad intelectual a menos que tenga nuestro permiso expreso.</li>
        <li><strong>Su licencia a ITC Soluciones.</strong>Para operar y proporcionar nuestros Servicios, usted otorga a ITC Soluciones una licencia mundial, no exclusiva, libre de regalías, sublicenciable y transferible para usar, reproducir, distribuir, crear trabajos derivados, mostrar y realizar la información (incluido el contenido) que cargue, envíe, almacene, envíe o reciba en o a través de nuestros Servicios. Los derechos que concede en esta licencia tienen el propósito limitado de operar y proporcionar nuestros servicios (como permitirnos mostrar su estado, transmitir sus transacciones y mensajes, almacenar sus transacciones y mensajes no entregados en nuestros servidores por hasta 90 días).</li>
        <li><strong>Licencia de ITC Soluciones para usted.</strong>Le otorgamos una licencia limitada, revocable, no exclusiva, no sublicenciable e intransferible para utilizar nuestros Servicios, de conformidad con nuestras Condiciones. Esta licencia tiene el único propósito de permitirle utilizar nuestros Servicios, de la manera permitida por nuestros Términos. No se concede ninguna licencia o derecho por implicación o por cualquier otro medio, excepto las licencias y derechos expresamente otorgados a usted.</li>
        </ul>

        <h2>Descargo de responsabilidad</h2>
         <p>USTED UTILIZA NUESTROS SERVICIOS POR SU PROPIO RIESGO Y ESTÁ SUJETO A LAS SIGUIENTES RENUNCIAS. ESTAMOS OFRECIENDO NUESTROS SERVICIOS "TAL COMO ESTÁ" SIN NINGUNA GARANTÍA EXPRESA O IMPLÍCITA, INCLUYENDO, PERO SIN LIMITARSE A, GARANTÍAS DE COMERCIABILIDAD, ADECUACIÓN PARA UN PROPÓSITO PARTICULAR, TÍTULO, NO INFRACCIÓN Y LIBERTAD DE VIRUS DE ORDENADOR O DE OTROS DAÑOS CÓDIGO. NO GARANTIZAMOS QUE CUALQUIER INFORMACIÓN PROPORCIONADA POR NOSOTROS ES EXACTA, COMPLETA O ÚTIL, QUE NUESTROS SERVICIOS SERÁ OPERACIONAL, LIBRE DE ERRORES, SEGURO O SEGURO, O QUE NUESTROS SERVICIOS FUNCIONARÁN SIN INTERRUPCIONES, RETRASOS O IMPERFECCIONES. NO CONTROLAMOS, Y NO SOMOS RESPONSABLES, CONTROLANDO CÓMO O CUANDO NUESTROS USUARIOS UTILIZAN NUESTROS SERVICIOS O LAS CARACTERÍSTICAS, SERVICIOS E INTERFACES QUE OFRECEN NUESTROS SERVICIOS. NO SOMOS RESPONSABLES DE Y NO ESTAMOS OBLIGADOS A CONTROLAR LAS ACCIONES O LA INFORMACIÓN (INCLUYENDO CONTENIDO) DE NUESTROS USUARIOS U OTROS TERCEROS. USTEDES, NUESTROS SUBSIDIARIOS, AFILIADOS Y NUESTRO Y SUS DIRECTORES, OFICIALES, EMPLEADOS, SOCIOS Y AGENTES DE CUALQUIER RECLAMO, QUEJA, CAUSA DE ACCIÓN, CONTROVERSIA O DISPUTA (JUNTOS, RECLAMACIÓN ") Y DAÑOS, CONOCIDOS Y DESCONOCIDOS, RELACIONADOS CON, RESULTANTES O DE CUALQUIER MANERA RELACIONADOS CON CUALQUIER DEMANDA QUE TENGAN CONTRA CUALQUIER TERCERO. USTED RENUNCIA A CUALQUIER DERECHO QUE USTED PUEDA TENER BAJO CÓDIGO CIVIL DE CALIFORNIA NRO 1542, O CUALQUIER OTRO ESTATUTO O LEY APLICABLE SIMILAR DE CUALQUIER OTRA JURISDICCIÓN, QUE DICE QUE: UNA LIBERACIÓN GENERAL NO SE EXTIENDE A LAS RECLAMACIONES QUE EL CREDIENTE NO SABE O SOSPECHA PARA EXISTIR EN SU FAVOR EN EL MOMENTO DE LA EJECUCIÓN DE LA LIBERACIÓN, QUE SI SE CONOCE POR ELLA O SU DEBE HABER MATERIALMENTE AFECTADO SU ACUERDO CON EL DEUDOR.</p>

        <h2>Limitación de responsabilidad</h2>
        <p>ITC SOLUCIONES O SUS EMPLEADOS NO SERÁN RESPONSABLES ANTES USTED POR CUALQUIER PERDIDA DE BENEFICIOS O DAÑOS CONSECUENCIALES, ESPECIALES, PUNITIVOS, INDIRECTOS O INCIDENTALES RELACIONADOS CON, RESULTANTES O EN CUALQUIER FORMA EN RELACIÓN CON NUESTROS TÉRMINOS, NOSOTROS O NUESTROS SERVICIOS, INCLUSO SI EL PERSONAL DE ITC SOLUCIONES HUBIESE SIDO ADVERTIDO DE LA POSIBILIDAD DE TALES DAÑOS. NUESTRA RESPONSABILIDAD AGREGADA RELATIVA A, QUE SURJA DE, O EN CUALQUIER FORMA EN RELACIÓN CON NUESTROS TÉRMINOS, NOSOTROS O NUESTROS SERVICIOS NO EXCEDERÁ EL MÁS GRANDE DE UN PESO ARGENTINO ($1) O LA CANTIDAD QUE USTED HAYA PAGADO EN LOS ÚLTIMOS DOCE MESES. LAS LEYES DE ALGUNOS ESTADOS O JURISDICCIONES PUEDEN NO PERMITIR LA EXCLUSIÓN O LIMITACIÓN DE CIERTOS DAÑOS, POR LO QUE CIERTAS O TODAS LAS EXCLUSIONES Y LIMITACIONES ESTABLECIDAS ANTERIORMENTE PUEDEN NO APLICARSE A USTED. A PESAR DE LO CONTRARIO EN NUESTROS TÉRMINOS, EN TALES CASOS, LA RESPONSABILIDAD DE ITC SOLUCIONES SERÁ LIMITADA A LA MÁXIMA MEDIDA PERMITIDA POR LA LEY APLICABLE.</p>

        <h2>Resolución de conflictos</h2>
        <p>Las partes se someten, para el caso de cualquier controversia originada en la presente contrato, a la competencia de los Tribunales Ordinarios de la Provincia de Mendoza haciendo expresa renuncia de cualquier otro fuero o jurisdicción que pudiera corresponder por aplicación de los criterios legales de distribución de la competencia, incluso a la competencia de tribunales federales de Argentina o de otros paises.</p>

        <h2>Disponibilidad y terminación de nuestros servicios</h2>
        <ul>
            <li><strong>Disponibilidad de Nuestros Servicios.</strong>Nuestros Servicios pueden ser interrumpidos, incluyendo para mantenimiento, reparaciones, actualizaciones, fallas en la red o equipo. Podemos suspender algunos o todos nuestros Servicios, incluidas ciertas características y el soporte para ciertos dispositivos y plataformas, en cualquier momento. Eventos fuera de nuestro control pueden afectar nuestros Servicios, tales como eventos en naturaleza y otros eventos de fuerza mayor.</li>
            <li><strong>Terminación.</strong>Podemos modificar, suspender o terminar su acceso o uso de nuestros Servicios en cualquier momento y por cualquier motivo, como por ejemplo, si usted viola la letra o el espíritu de nuestros Términos o crea daño, riesgo o posible exposición legal para nosotros, nuestros usuarios o otros. Las siguientes disposiciones sobrevivirán a cualquier terminación de su relación con ITC Soluciones: "Licencias", "Renuncias", "Limitación de responsabilidad", "Indemnización", "Resolución de disputas", "Disponibilidad y terminación de nuestros servicios", "Otros".</li>
        </ul>

        <h2>Otro</h2>

        <ul>
            <li>A menos que un acuerdo mutuamente ejecutado entre usted y nosotros indique lo contrario, nuestros Términos constituyen el acuerdo completo entre usted y nosotros con respecto a PuntNut y nuestros Servicios y reemplazan cualquier acuerdo anterior.</li>
            <li>Podemos pedirle que acepte términos adicionales para algunos de nuestros Servicios en el futuro, los cuales regirán en la medida en que haya un conflicto entre nuestros Términos y términos adicionales.</li>
            <li>Nuestros Servicios no están destinados a ser distribuidos ni utilizados en ningún país donde dicha distribución o uso violen la legislación local o nos sometan a cualquier regulación en otro país. Nos reservamos el derecho de limitar nuestros servicios en cualquier país.</li>
            <li>Nuestros términos están escritos en español. Cualquier versión traducida se proporciona solamente para su conveniencia. En la medida en que cualquier versión traducida de nuestros términos entre en conflicto con la versión en español, la versión en español es la única válida.</li>
            <li>Cualquier modificación o renuncia de nuestros Términos requiere nuestro consentimiento expreso.</li>
            <li>Podemos modificar o actualizar estos Términos.</li>
            <li>Usted no transferirá ninguno de sus derechos u obligaciones bajo nuestros Términos a otra persona sin nuestro previo consentimiento por escrito.</li>
            <li>Nada en nuestros Términos nos impedirá cumplir con la ley.</li>
            <li>Excepto según lo contemplado aquí, nuestros Términos no otorgan ningún derecho de terceros beneficiarios.</li>
            <li>Si alguna cláusula de estas Condiciones se considera ilícita, nula o por cualquier razón no ejecutable, entonces dicha disposición será considerada separable de nuestros Términos y no afectará la validez y aplicabilidad de las disposiciones restantes.</li>
            <li>Nos reservamos todos los derechos no expresamente concedidos por nosotros a usted. En ciertas jurisdicciones, usted puede tener derechos legales como consumidor, y nuestros Términos no tienen la intención de limitar tales derechos legales de consumidores que no pueden ser renunciados por contrato.</li>
            <li>Siempre agradecemos sus comentarios u otras sugerencias sobre ITC Soluciones y nuestros Servicios, pero usted entiende que podemos usar sus comentarios o sugerencias sin ninguna obligación de compensarlos (así como usted no tiene obligación de ofrecerlos).</li>
        </ul>


`
    },
    {
        id: '80ba5106-5f9c-4ed7-b8f3-8544035e3095',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug: 'politica-privacidad-Nuvalid',
        title: 'Política de privacidad de NUVALID',
        subtitle: '',
        content: `<h2>Información que recopilamos</h2>
                <p>NUVALID recibe o recopila información para poder funcionar, incluso cuando instala, accede o usa nuestros servicios.</p>
                <h3>Información que usted proporciona</h3>
                <ul>
                    <li><strong>Información de su cuenta.</strong>Usted proporciona su nombre completo, clave fiscal, dirección de atención, especialidad médica, número de matrícula médica, correo electrónica y número de teléfono móvil para crear una cuenta de NUVALID. Usted nos proporciona los números de teléfono en su libreta de direcciones móvil de forma regular, incluidos los de los usuarios de nuestros Servicios y sus otros contactos. Usted confirma que está autorizado a proporcionarnos dichos números.</li>
                    <li><strong>Tus transacciones y mensajes.</strong>No retenemos sus mensajes en el curso normal de la prestación de nuestros servicios a usted. Una vez que sus mensajes (incluidos sus transacciones, protocolos médicos, fotos, videos, mensajes de voz, archivos e información de ubicación compartida) se entreguen, se eliminarán de nuestros servidores. Sus mensajes se almacenan en su propio dispositivo. Si un mensaje no puede ser entregado inmediatamente (por ejemplo, si está desconectado), podemos mantenerlo en nuestros servidores durante un máximo de 180 días a medida que tratamos de entregarlo. Si todavía no se entrega un mensaje después de 30 días, lo eliminamos.</li>
                    <li><strong>Atención al cliente.</strong>Usted puede proporcionarnos información relacionada con el uso de nuestros Servicios, incluyendo copias de sus mensajes, y cómo comunicarse con usted para brindarle soporte al cliente. Por ejemplo, puede enviarnos un correo electrónico con información relacionada con el rendimiento de nuestra aplicación u otros problemas.</li>
                </ul>
                <h3>Información Recopilada Automáticamente</h3>
                <ul>
                <li><strong>Información de uso y registro.</strong>Recopilamos información relacionada con el servicio, el diagnóstico y el rendimiento. Esto incluye información sobre su actividad (por ejemplo, cómo usa nuestros servicios, cómo interactúa con otros usuarios de nuestros servicios y similares), archivos de registro y diagnósticos, bloqueos, sitios web y registros e informes de rendimiento.</li>
                <li><strong>Información de dispositivo y conexión.</strong>Recopilamos información específica del dispositivo al instalar, acceder o utilizar nuestros Servicios. Esto incluye información como modelo de hardware, información del sistema operativo, información del navegador, dirección IP, información de la red móvil, incluido el número de teléfono, e identificadores de dispositivos. Recopilamos información de ubicación del dispositivo si utiliza nuestras funciones de ubicación, como cuando decide compartir su ubicación con sus contactos, ver ubicaciones cercanas o las que otros han compartido con usted, y similares, y para fines de diagnóstico y solución de problemas, Están teniendo problemas con las características de ubicación de nuestra aplicación.</li>
                <li><strong>Cookies.</strong> Utilizamos cookies para operar y proporcionar nuestros servicios, incluso para proporcionar nuestros servicios que están basados ​​en la web, mejorar sus experiencias, entender cómo se están utilizando nuestros servicios y personalizar nuestros servicios. Podemos usar cookies para entender cuáles de nuestras preguntas frecuentes son más populares y para mostrarle contenido relevante relacionado con nuestros servicios. Además, podemos utilizar cookies para recordar sus opciones, como sus preferencias de idioma, y ​​de lo contrario personalizar nuestros servicios para usted.</li>
                <li><strong>Información de estado.</strong>Recopilamos información acerca de los cambios de los mensajes en línea y de estado en nuestros Servicios, como si está en línea (su "estado en línea"), cuando utilizó por última vez nuestros Servicios (su último estado visto) y cuándo actualizó su estado por última vez mensaje.</li>
                </ul>
                <h3>Información de terceros</h3>
                <ul>
                    <li><strong>Información que otros proveen sobre usted.</strong>Recibimos información que otras personas nos proporcionan, que puede incluir información sobre usted. Por ejemplo, cuando otros usuarios conozcan nuestros Servicios, pueden proporcionar su número de teléfono desde su libreta de direcciones móvil (tal como puede proporcionar la suya), o pueden enviarle un mensaje.</li>
                    <li><strong>Proveedores de terceros.</strong>Trabajamos con terceros proveedores para ayudarnos a operar, proveer, mejorar, entender, personalizar, apoyar y comercializar nuestros Servicios. Por ejemplo, trabajamos con empresas para distribuir nuestras aplicaciones, proporcionar nuestra infraestructura, entrega y otros sistemas, proporcionar información de mapas y lugares, procesar pagos, ayudarnos a entender cómo las personas usan nuestros servicios y comercializar nuestros servicios. Estos proveedores pueden proporcionarnos información sobre usted en ciertas circunstancias. Por ejemplo, las tiendas de aplicaciones pueden proporcionarnos informes para ayudarnos a diagnosticar y solucionar problemas de servicio.</li>
                    <li><strong>Servicios de terceros.</strong>Le permitimos utilizar nuestros Servicios en relación con servicios de terceros. Si utiliza nuestros Servicios con dichos servicios de terceros, podemos recibir información sobre usted de ellos. Tenga en cuenta que cuando utilice servicios de terceros, sus propios términos y políticas de privacidad regirán el uso de dichos servicios.</li>
                </ul>
                <h3>Cómo utilizamos la información</h3>
                <p>Utilizamos toda la información que tenemos para ayudarnos a operar, proporcionar, mejorar, entender, personalizar, apoyar y comercializar nuestros servicios.</p>
                <ul>
                    <li><strong>Nuestros servicios.</strong>Operamos y proporcionamos nuestros servicios, incluyendo el servicio de atención al cliente y la mejora, fijación y personalización de nuestros servicios. Entendemos cómo las personas usan nuestros Servicios y analizamos y utilizamos la información que tenemos para evaluar y mejorar nuestros Servicios, investigar, desarrollar y probar nuevos servicios y características y llevar a cabo actividades de solución de problemas. También usamos su información para responderle cuando nos contacta. Utilizamos cookies para operar, proporcionar, mejorar, entender y personalizar nuestros servicios.</li>
                    <li><strong>Seguridad y protección</strong>Verificamos las cuentas y la actividad y promovemos la seguridad y protección dentro y fuera de nuestros Servicios, por ejemplo investigando actividades sospechosas o violaciones de nuestros Términos y asegurando que nuestros Servicios se están utilizando legalmente.</li>
                    <li><strong>Comunicaciones Sobre Nuestros Servicios.</strong>Nos comunicamos con usted acerca de nuestros servicios y características y le informamos sobre nuestros términos y políticas y otras actualizaciones importantes.</li>
                    <li><strong>No hay anuncios de banner de terceros.</strong>No permitimos anuncios de banner de terceros en WhatsApp. No tenemos intención de presentarlos, pero si lo hacemos, actualizaremos esta política.</li>
                    <li><strong>Mensajería Comercial.</strong>Permitiremos que usted y terceros, como empresas, se comuniquen entre sí mediante NUVALID, por ejemplo, a través de solicitudes de autorización, notificaciones de entrega y envío.</li>
                </ul>



`
    },
    // {
    //     id: 'db2e97a6-d657-4e9d-9b6c-5f213ea3301c',
    //     categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
    //     slug: 'closing-your-account',
    //     title: 'Closing your account',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // {
    //     id: '3374c887-2fb7-4223-9f40-7f2cbbf76795',
    //     categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
    //     slug: 'account-limits',
    //     title: 'Account limits',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
    // {
    //     id: 'cc65f92a-7d46-4557-b15b-6f8f59a60576',
    //     categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
    //     slug: 'two-factor-authentication',
    //     title: 'Two factor authentication',
    //     subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    // },
];

// Since we only have one content for the demo, we will 
// use the following mock-api on every request for every guide.
export const guideContent = `
<h2>Header Level 2</h2>

<p>
    <strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit
    amet, ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper
    pharetra. Vestibulum erat wisi, condimentum sed, <code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci,
    sagittis tempus lacus enim ac dui. <a href="#"
                                          class="link">Donec non enim</a>
    in turpis pulvinar facilisis. Ut felis.
</p>

<p>
    Orci varius natoque penatibus et magnis dis <em>parturient montes</em>, nascetur ridiculus mus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
    himenaeos. Curabitur vitae sagittis odio. <mark>Suspendisse</mark> ullamcorper nunc non pellentesque laoreet. Curabitur eu tortor id quam pretium mattis. Proin ut quam velit.
</p>

<h3>Header Level 3</h3>

<img src="assets/images/pages/help-center/image-1.jpg">
<p class="text-secondary">
    <em>Nullam sagittis nulla in diam finibus, sed pharetra velit vestibulum. Suspendisse euismod in urna eu posuere.</em>
</p>

<h4>Header Level 4</h4>

<blockquote>
    <p>
        Blockquote. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur
        massa. Donec eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est.
    </p>
    <footer>
        Brian Hughes
    </footer>
</blockquote>

<ol>
    <li>Ordered list</li>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
</ol>

<h5>Header Level 5</h5>

<ul>
    <li>Unordered list</li>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
</ul>

<pre><code>#header h1 a {
    display: block;
    width: 300px;
    height: 80px;
}</code></pre>

<h6>Header Level 6</h6>

<dl>
    <dt>Definition list</dt>
    <dd>
        Quisque sit amet risus enim. Aliquam sit amet interdum justo, at ultricies sapien. Suspendisse et semper urna, in gravida eros. Quisque id nibh iaculis, euismod urna sed,
        egestas nisi. Donec eros metus, congue a imperdiet feugiat, sagittis nec ipsum. Quisque dapibus mollis felis non tristique.
    </dd>

    <dt>Definition list</dt>
    <dd>
        Ut auctor, metus sed dapibus tempus, urna diam auctor odio, in malesuada odio risus vitae nisi. Etiam blandit ante urna, vitae placerat massa mollis in. Duis nec urna ac
        purus semper dictum ut eget justo. Aenean non sagittis augue. Sed venenatis rhoncus enim eget ornare. Donec viverra sed felis at venenatis. Mauris aliquam fringilla nulla,
        sit amet congue felis dignissim at.
    </dd>
</dl>`;
