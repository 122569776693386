import * as models from '../../store/models/store.models';
import * as sugerencias from '../../store/actions/sugerencias.action';

const estadoInicial: models.sugerenciasState = {
  SugerenciasRequest: null,
};

export function sugerenciasReducer(
  state = estadoInicial,
  action: sugerencias.sugerenciasActions
): models.sugerenciasState {
  switch (action.type) {
    case sugerencias.SETSUGERENCIASREQUEST:
      return {
        ...state,
        SugerenciasRequest:
          action.SugerenciasRequest,
      };
    default:
      return state;
  }
}
