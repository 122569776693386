import * as models from '../../store/models/store.models';
import * as fromUI from '../../store/actions/configuracion.action';
import { UsuarioActualModel } from '../../models/login.models';
import { SETDATOSTIPOSPRESCRIPCIONES } from '../actions/configuracion.action';

const estadoInicial: models.ConfiguracionState = {
  codigosInstalacion: null,
  usuarioActual: null,
  datosIniciales: null,
  configuracionUsuario: null,
};

export function configuracionReducer(
  state = estadoInicial,
  action: fromUI.configuracionActions
): models.ConfiguracionState {
  switch (action.type) {
    case fromUI.SET_CODIGOINSTALACION:          
      if (state.codigosInstalacion != null && state.codigosInstalacion.length >0) {
        let codigosInstalacion = [...state.codigosInstalacion];        
        
        if (
          state.codigosInstalacion.filter(
            (codInstalacion:any) =>
              codInstalacion.codigoInstalacion ==
              action.codigosInstalacion[0].codigoInstalacion
          ).length == 0
        ) {
          codigosInstalacion.push(action.codigosInstalacion[0]);
        }
        return {
          ...state,
          codigosInstalacion: codigosInstalacion,
        };
      } else {
        return {
          ...state,
          codigosInstalacion: action.codigosInstalacion,
        };
      }

    case fromUI.SETUSUARIOACTIVO:
      return {
        ...state,
        usuarioActual: action.usuarioActual,
      };

    case fromUI.REMOVECODIGOINSTALACION:
      if (state.codigosInstalacion) {
        let codigosInstalacion = [...state.codigosInstalacion];
        codigosInstalacion.splice(
          codigosInstalacion.findIndex(
            (x) => x.codigoInstalacion == action.codigoInstalacion
          ),
          1
        );
        if (codigosInstalacion.length == 0) {
          return {
            ...state,
            codigosInstalacion: null,
          };
        } else {
          return {
            ...state,
            codigosInstalacion: codigosInstalacion,
          };
        }
      } else {
        return state;
      }

    case fromUI.SETNUEVOTOKEN:
      if (state.usuarioActual) {
        return {
          ...state,
          usuarioActual: {
            ...state.usuarioActual,
            token: action.nuevoToken,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case fromUI.SETDATOSCONFIGURACION:
      return {
        ...state,
        codigosInstalacion: action.datosConfiguracion.codigosInstalacion,
        usuarioActual: action.datosConfiguracion.usuarioActual,
      };

    case fromUI.REMOVEDATOSUSUARIO:
      return {
        ...state,
        usuarioActual: null
      };

    case fromUI.REMOVEDATOSCONFIGURACION:
      return {
        ...state,
        codigosInstalacion: null,
        usuarioActual: null,
      };

    case fromUI.SETCONFIGURACIONUSUARIO:
      return {
        ...state,
        configuracionUsuario: action.configuracionUusario,
      };

    case fromUI.UNSETCONFIGURACIONUSUARIO:
      return {
        ...state,
        configuracionUsuario: null,
      };

    case fromUI.SETDATOSINICIALES:
      return {
        ...state,
        datosIniciales: action.datosIniciales,
      };

    case fromUI.UNSETDATOSINICIALES:
      return {
        ...state,
        datosIniciales: null,
      };

    case fromUI.UNSETCONFIGURACION:
      return {
        ...state,
        usuarioActual: null,
        datosIniciales: null,
        configuracionUsuario: null,
      };

    case fromUI.SETDATOSTIPOSTRANSACCIONES:

      return {
        ...state,
        datosIniciales: {
          financiadores: state.datosIniciales?.financiadores || null,
          prestadores: state.datosIniciales?.prestadores || null,
          prestadoresSeleccionados:
            state.datosIniciales?.prestadoresSeleccionados || null,
          //tiposTransacciones: !action.prescribe ? action.tiposTransacciones.filter(tipo => tipo.codigo != '02R') : action.tiposTransacciones,
          tiposTransacciones: action.tiposTransacciones,
          tiposPrescripciones: state.datosIniciales?.tiposPrescripciones || null,
        },
      };

    case fromUI.SETDATOSTIPOSPRESCRIPCIONES:
        
        return {
          ...state,
          datosIniciales: {
            financiadores: state.datosIniciales?.financiadores || null,
            prestadores: state.datosIniciales?.prestadores || null,
            prestadoresSeleccionados:
              state.datosIniciales?.prestadoresSeleccionados || null,
            tiposTransacciones: state.datosIniciales?.tiposTransacciones || null,
            tiposPrescripciones: action.tiposPrescriciones
          },
    };

    default:
      return state;
  }
}
