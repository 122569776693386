// export * from './ui.reducer';
export * from './instalacion.reducer';
export * from './configuracion.reducer';
export * from './datosInicio.reducer';
export * from './datosTransacciones.reducer';
export * from './estadoFormsInicio.reducer';
export * from './recetas.reducer';
export * from './filter.reducer';
export * from './sugerencias.reducer';
export * from './alternativeMedicament.reducer';
export * from './datosInicialesExtra.reducer';
export * from './addMedicament.reducer';
