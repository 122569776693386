/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Inicio',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/home'
    },
    {
        id: 'tickets',
        title: 'Tickets',
        type: 'basic',
        icon: 'heroicons_outline:document-magnifying-glass',
        link: '/tickets'
    },
    {
        id: 'apps.help-center',
        title: 'Centro de ayuda',
        type: 'collapsable',
        icon: 'heroicons_outline:information-circle',
        link: '/help-center',
        children: [
            {
                id: 'apps.help-center.home',
                title: 'Inicio',
                type: 'basic',
                link: '/help-center',
                exactMatch: true,
            },
            {
                id: 'apps.help-center.faqs',
                title: 'FAQs',
                type: 'basic',
                link: '/help-center/faqs',
            },
            {
                id: 'apps.help-center.guides',
                title: 'Guías',
                type: 'basic',
                link: '/help-center/guides',
            },
            {
                id: 'apps.help-center.support',
                title: 'Soporte',
                type: 'basic',
                link: '/help-center/support',
            },
        ],
    },
    {
        id: 'settings',
        title: 'Configuración',
        type: 'basic',
        icon: 'heroicons_outline:cog-8-tooth',
        link: '/settings'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
