import * as MedicamentosActions from '../actions/addMedicament.action';
import { Medicamento } from '../models/store.models';

export interface State {
  medicamentos: Medicamento[];
}

const initialState: State = {
  medicamentos: [],
};

export function addMedicamentReducer(
  state: State = initialState,
  action: MedicamentosActions.MedicamentosActions
): State {
  switch (action.type) {
    case MedicamentosActions.AGREGAR_MEDICAMENTO:
      return {
        ...state,
        medicamentos: [...state.medicamentos, action.payload],
      };
    default:
      return state;
  }
}
