import { TicketFiltrosModel } from '../../models/ticket.models';
import { Action } from '@ngrx/store';

export const SETFILTERTICKETSREQUEST =
    '[filters] Set FILTERTICKETSREQUEST';
export const UNSETFILTERTICKETSREQUEST =
    '[filters] UnSet FILTERTICKETSREQUEST';

export class setFilterTicketsRequest implements Action {
    readonly type = SETFILTERTICKETSREQUEST;
    constructor(public FilterTicketsRequest: TicketFiltrosModel | null) { }
}

export class unsetFilterTicketsRequest implements Action {
    readonly type = UNSETFILTERTICKETSREQUEST;
}

export type filterTicketsActions =
    | setFilterTicketsRequest
    | unsetFilterTicketsRequest
