import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { codigoInstalacion, redirectClass } from '../models/global.models';
import { LoginModel, UsuarioActualModel } from '../models/login.models';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducers';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  login(infoLogin: LoginModel): Observable<UsuarioActualModel> {
    return this.http
      .post<UsuarioActualModel>(environment.url + '/usuarios/signin', infoLogin)
      .pipe(take(1));
  }

  recuperarContrasenna(email: string): Observable<any> {
    let body = { email: email };
    return this.http
      .post<string>(environment.url + '/usuarios/forgot_password', body, {
        responseType: 'json',
      })
      .pipe(take(1));
  }

  crearCuentaInstalacion(
    infoLogin: LoginModel,
    rol: string,
    cuitPrestador: string
  ): Observable<any> {
    let informacion = {
      ...infoLogin,
      rol: rol,
      cuitPrestador: cuitPrestador,
    };
    return this.http
      .post(environment.url + '/usuarios/signin/instalacion', informacion)
      .pipe(take(1));
  }

}
