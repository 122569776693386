import { Action } from '@ngrx/store';
import { Medicamento } from '../models/store.models';

export const AGREGAR_MEDICAMENTO = '[Medicamentos] Agregar Medicamento';

export class addMedicament implements Action {
  readonly type = AGREGAR_MEDICAMENTO;

  constructor(public payload: any) {}
}

export type MedicamentosActions = addMedicament;
