import { Action } from '@ngrx/store';

export const SETESTADOFORMCREDENCIALREQUESTFORM =
  '[datosInicio] Set estadoFormcredencialRequestForm';

export const SETESTADOFORMCODIGODESEGURIDADREQUESTFORM =
  '[datosInicio] Set SETESTADOFORMCODIGODESEGURIDADREQUESTFORM';

export const SETESTADOFORMFINANCIADORREQUEST =
  '[datosInicio] Set estadoFormfinanciador';

export const SETESTADOFORMPRESTADORREQUEST =
  '[datosInicio] Set estadoFormprestador';

export const SETESTADOFORMTRANSACCIONREQUEST =
  '[datosInicio] Set estadoFormtransaccion';



export class setEstadoFormCredencialRequest implements Action {
  readonly type = SETESTADOFORMCREDENCIALREQUESTFORM;
  constructor(public formEstadoCredencialRequest: boolean | null) { }
}

export class setEstadoFormCodigoDeSeguridadRequest implements Action {
  readonly type = SETESTADOFORMCODIGODESEGURIDADREQUESTFORM;
  constructor(public formEstadoCodigoDeSeguridadRequest: boolean | null) { }
}
export class setEstadoFormFinanciadorRequest implements Action {
  readonly type = SETESTADOFORMFINANCIADORREQUEST;
  constructor(public formEstadoFinanciadorRequest: boolean | null) { }
}
export class setEstadoFormPrestadorRequest implements Action {
  readonly type = SETESTADOFORMPRESTADORREQUEST;
  constructor(public formEstadoPrestadorRequest: boolean | null) { }
}
export class setEstadoFormTransaccionRequest implements Action {
  readonly type = SETESTADOFORMTRANSACCIONREQUEST;
  constructor(public formEstadoTransaccionRequest: boolean | null) { }
}


export type estadoFormsActions =
  | setEstadoFormCredencialRequest
  | setEstadoFormCodigoDeSeguridadRequest
  | setEstadoFormFinanciadorRequest
  | setEstadoFormPrestadorRequest
  | setEstadoFormTransaccionRequest