import { codigoNombreModel } from '../../models/datosInicialesExtras.models';
import { codigoInstalacion } from '../../models/global.models';
import { UsuarioActualModel } from '../../models/login.models';
import { Action } from '@ngrx/store';
import {
  ConfiguracionState,
  ConfiguracionUsuario,
  DatosIniciales,
} from '../../store/models/store.models';

export const SET_CODIGOINSTALACION =
  '[configuracion] Se setea el codigo de instalacion';

export const SETDATOSCONFIGURACION =
  '[configuracion] Se agregan datos de configuracion';

export const REMOVEDATOSUSUARIO =
  '[configuracion] Se remueven datos del usuario';

export const REMOVECODIGOINSTALACION =
  '[configuracion] Se remueven datos del codigo de instalacion del sistema';

export const REMOVEDATOSCONFIGURACION =
  '[configuracion] Se remueven datos de configuracion';

export const SETUSUARIOACTIVO = '[configuracion] Set usuario activo';

export const SETDATOSINICIALES = '[configuracion] Set datos iniciales';

export const UNSETDATOSINICIALES = '[configuracion] Unset datos iniciales';

export const SETDATOSTIPOSTRANSACCIONES =
  '[configuracion] SETDATOSTIPOSTRANSACCIONES';

export const SETDATOSTIPOSPRESCRIPCIONES = 
  '[configuracion] SETDATOSTIPOSPRESCRIPCIONES';

export const SETCONFIGURACIONUSUARIO =
  '[configuracion] Set configuracion usuario';

export const UNSETCONFIGURACIONUSUARIO =
  '[configuracion] Unset configuracion usuario';

export const UNSETCONFIGURACION = '[configuracion] Unset configuracion';

export const SETNUEVOTOKEN = '[configuracion] Set nuevo token';

export class setCodigoInstalacion implements Action {
  readonly type = SET_CODIGOINSTALACION;
  constructor(public codigosInstalacion: Array<codigoInstalacion>) {}
}

export class setUsuarioActual implements Action {
  readonly type = SETUSUARIOACTIVO;
  constructor(public usuarioActual: UsuarioActualModel) {}
}

export class setDatosConfiguracion implements Action {
  readonly type = SETDATOSCONFIGURACION;
  constructor(public datosConfiguracion: ConfiguracionState) {}
}

export class removeCodigoInstalacion implements Action {
  readonly type = REMOVECODIGOINSTALACION;
  constructor(public codigoInstalacion: string) {}
}

export class removeDatosUsuario implements Action {
  readonly type = REMOVEDATOSUSUARIO;
}

export class removeDatosConfiguracion implements Action {
  readonly type = REMOVEDATOSCONFIGURACION;
}

export class setNuevoToken implements Action {
  readonly type = SETNUEVOTOKEN;
  constructor(public nuevoToken: string) {}
}

export class setDatosIniciales implements Action {
  readonly type = SETDATOSINICIALES;
  constructor(public datosIniciales: DatosIniciales) {}
}

export class unsetDatosIniciales implements Action {
  readonly type = UNSETDATOSINICIALES;
}

export class setDatosTiposTransacciones implements Action {
  readonly type = SETDATOSTIPOSTRANSACCIONES;
  constructor(public tiposTransacciones: Array<codigoNombreModel>) {}
}

export class setDatosTiposPrescriciones implements Action {
  readonly type = SETDATOSTIPOSPRESCRIPCIONES;
  constructor(public tiposPrescriciones: Array<codigoNombreModel>) {}
}

export class setConfiguracionUsuario implements Action {
  readonly type = SETCONFIGURACIONUSUARIO;
  constructor(public configuracionUusario: ConfiguracionUsuario) {}
}

export class unsetConfiguracionUsuario implements Action {
  readonly type = UNSETCONFIGURACIONUSUARIO;
}

export class unsetConfiguracion implements Action {
  readonly type = UNSETCONFIGURACION;
}

export type configuracionActions =
  | setCodigoInstalacion
  | removeDatosConfiguracion
  | setUsuarioActual
  | setDatosConfiguracion
  | removeDatosUsuario
  | setNuevoToken
  | removeCodigoInstalacion
  | setDatosIniciales
  | unsetDatosIniciales
  | setDatosTiposTransacciones
  | setDatosTiposPrescriciones
  | setConfiguracionUsuario
  | unsetConfiguracionUsuario
  | unsetConfiguracion;
